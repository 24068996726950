.banner-porfile-area {
    position: relative;
}

.banner-porfile-area .col-12 {
    padding: 0;
}

.banner-porfile-area .image-banner-area {
    position: relative;
    width: 100%;
}

.image-banner-area {}

.banner-porfile-area .image-banner-area .img-box {
    position: relative;
    z-index: 0;
}

.banner-porfile-area .image-banner-area .img-box img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    position: relative;
    z-index: 0;
}

.banner-porfile-area .image-banner-area .input-file {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 99;
}

.banner-porfile-area .image-banner-area .input-file input#upload {
    width: 60px;
    height: 60px;
    opacity: 0;
    position: relative;
    z-index: 999;
    cursor: pointer;
    top: 9px;
}

.banner-porfile-area .image-banner-area .input-file .content-btn {
    position: relative;
    margin-top: -52px;
}

.banner-porfile-area .image-banner-area .input-file .content-btn button {
    width: 60px;
    height: 60px;
    background-color: transparent;
    border: none;
    position: relative;
    z-index: 99;
    transition: all 0.3s ease-in-out;
}
.banner-porfile-area .image-banner-area:hover .input-file .content-btn button {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0 0 0 / 30%);
}
.banner-porfile-area .image-banner-area:hover .input-file .content-btn button {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0 0 0 / 30%);
}

.porfile-sec .profile-area .input-file {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    overflow: hidden;
    transform: translate(-50%, -50%);
}

.porfile-sec .profile-area .input-file input {
    opacity: 0;
    position: relative;
    width: 100%;
    top: 1px;
    height: 100%;
    z-index: 999;
}

.porfile-sec .profile-area .input-file .content-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.porfile-sec .profile-area .input-file .content-btn button {
    background-color: transparent;
    border: none;
}

.porfile-sec .profile-area .input-file .content-btn button img {
    box-shadow: none;
    height: 18px;
    width: 18px;
    border-radius: 0;
    border: none;
}
.porfile-sec .profile-area:before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -22px;
    width: 71%;
    height: 2px;
    background-color: #f6f6f6;
    transform: translateX(-50%);
}
.porfile-sec {
    position: relative;
    padding: 20px 0;
    background-color: #f5f5f570;
    background-image: url('../../static/images/roadmap-1.jpg');
}
.porfile-sec:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000060;
}
.porfile-sec .profile-area {
    position: relative;
    /* margin-top: -178px; */
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.porfile-sec .profile-area .img-box {
    position: relative;
    margin-right: 20px;
}

.porfile-sec .profile-area .img-box img {
    width: 190px;
    height: 190px;
    border-radius: 50%;
    border: 5px solid #fff;
    box-shadow: 0 0 20px rgba(0 0 0 / 7%);
}

.porfile-sec .profile-area .text-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

.porfile-sec .profile-area .text-box h4 {
    font-size: 26px;
    font-weight: 600;
    color: #1f2130;
    width: auto;
    display: inline-block;
    border-radius: 5px;
}
.porfile-sec .profile-area .text-box .heading-area {
    display: flex;
    align-items: center;
    justify-content: center;
}

.porfile-sec .profile-area .text-box .heading-area button {
    margin-left: 10px;
}

.porfile-sec .profile-area .text-box .heading-area button img {
    width: 16px;
    transition: all 0.3s ease-in-out;
}
.tranding-sec .items-sec .tranding-cards .no-item img {
    width: 102px;
    margin-bottom: 10px;
}
.tranding-sec .items-sec .tranding-cards .no-item h4 {
    color: #515d75;
    margin-bottom: 0;
}
.porfile-sec .profile-area .text-box .heading-area button:hover img {
    opacity: 0.7;
}
.modal-login .modal-area .table .no-nft-card {
    position: relative;
    text-align: center;
}

.modal-login .modal-area .table .no-nft-card .icon {
    padding-bottom: 20px;
    display: block;
}

.modal-login .modal-area .table .no-nft-card .icon img {
    width: 100px;
}

.modal-login .modal-area .table .no-nft-card h4 {
    font-size: 20px;
    font-weight: 600;
    color: #1f2130;
}
.porfile-sec .profile-area .text-box .text-eth {
    display: flex;
    align-items: center;
    justify-content: center;
}
.porfile-sec .profile-area .text-box .text-eth .icon img {
    width: 11px;
    position: relative;
    top: -1px;
    filter: brightness(200);
}
.porfile-sec .profile-area .text-box button .icon img {
    width: 15px !important;
}
.porfile-sec .profile-area .text-box .text-eth  .icon {
    margin-right: 11px;
}

.porfile-sec .profile-area .text-box p {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
}
.porfile-sec .profile-area .text-box button {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
}

.porfile-sec .profile-area .text-box button .icon {
    margin-right: 0;
    margin-left: 14px;
}

.porfile-sec .profile-area .text-box button .icon img {
    width: 16px;
}
.porfile-sec .profile-area .text-box .text-box {
    margin: 0 0 0 25px;
    font-size: 14px;
    font-weight: 500;
    color: #515d75;
}

.porfile-sec .profile-area .text-box .text-eth .text-box {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    color: #515d75;
    margin-left: 15px;
}
.porfile-sec .nav-tabs {
    border-bottom: 0;
    margin-top: 19px;
    margin-left: 15%;
}

.porfile-sec .nav-tabs .nav-item {
    margin-right: 38px;
}

.porfile-sec .nav-tabs .nav-item .nav-link {
    margin-bottom: 0;
    color: #515d75;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid transparent;
    border-left: none;
    border-right: none;
    border-top: none;
    padding: 0;
}

.porfile-sec .nav-tabs .nav-item.show .nav-link, .porfile-sec .nav-tabs .nav-item .nav-link.active {
    border-bottom: 2px solid #1f2130;
    color: #1f2130;
}
.tranding-sec .items-sec {
    position: relative;
}
.tranding-sec .items-sec h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 13px;
    color: #1f2130;
}
.tranding-sec .items-sec h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 23px;
    color: #1f2130;
}
.tranding-sec .items-sec .tranding-cards {
    position: relative;
    border: 1px solid #f5f5f7;
    padding: 30px 40px 10px;
    min-height: 348px;
    margin-bottom: 30px;
    border-radius: 10px;
}
.planet-sec .tranding-box .price-area p {
    margin-bottom: 0;
    color: #5b667c;
    font-size: 14px;
}
.modal-dialog.modal-login.style-two.modal-login.modal-dialog-centered {
    z-index: 9 !important;
}
.tranding-sec .items-sec .tranding-cards .no-item {
    text-align: center;
    padding: 116px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.tranding-sec .items-sec .tranding-cards .no-item .icon {
    margin-left: 15px;
}
.tranding-sec .items-sec .tranding-cards .no-item .img-box {
    margin-bottom: 27px;
}
.tranding-sec .items-sec .tranding-cards .no-item img {
    width: 43px;
    margin-bottom: 0;
}
.tranding-sec .items-sec .tranding-cards .no-item .img-box img {
    width: 200px;
}

.tranding-sec .items-sec .tranding-cards .no-item h3 {
    font-size: 27px;
    font-weight: 700;
    color: #1f2130;
    margin-bottom: 0;
}
@media(max-width: 1600px){
    .tranding-sec .items-sec .tranding-cards .no-item{
        padding: 65px 0;
    }
    .tranding-sec .items-sec .tranding-cards{
        min-height: 213px;
    }
}
@media(max-width: 1366px){
    .porfile-sec .profile-area .text-box p,
    .porfile-sec .profile-area .text-box .text-box{
        font-size: 12px;
    }
    .porfile-sec .profile-area .text-box .text-eth .icon img {
        width: 13px;
    }
    .porfile-sec .profile-area .text-box h4{
        font-size: 27px;
    }
    .tranding-sec .items-sec .tranding-cards {
        min-height: 147px;
    }
    .tranding-sec .items-sec .tranding-cards .no-item {
        padding: 33px 0;
    }
}
@media(max-width: 991px){
    .porfile-sec .nav-tabs{
        margin-left:0;
    }
    .porfile-sec .profile-area .img-box img{
        width: 135px;
        height: 135px;
    }
    .porfile-sec .profile-area {
        /* margin-top: -127px; */
    }
    .porfile-sec .profile-area .text-box h4{
        font-size: 23px;
    }
}

@media(max-width: 767px){
    .porfile-sec .profile-area .img-box img {
        width: 100px;
        height: 100px;
    }
    .porfile-sec .profile-area .text-box p {
        word-break: break-all;
        text-align: left;
    }
    .porfile-sec .profile-area {
        /* margin-top: -92px; */
    }
    .porfile-sec .nav-tabs {
        justify-content: center;
    }
    .banner-porfile-area .image-banner-area .img-box img {
        width: 100%;
        height: 115px;
    }
    .banner-porfile-area .image-banner-area .input-file {
        zoom: 0.7;
    }
    .banner-porfile-area .image-banner-area .input-file .content-btn button img {
        width: 27px;
    }
    .porfile-sec .profile-area .img-box {
        width: 150px;
    }
    .porfile-sec .profile-area .text-box p {
        word-break: break-all;
    }
}

@media(max-width: 400px){
    .porfile-sec .profile-area .img-box img {
        width: 80px;
        height: 80px;
    }
    .porfile-sec .profile-area {
        /* margin-top: -69px; */
    }
    .porfile-sec .profile-area .img-box {
        width: 90px;
    }
    
}