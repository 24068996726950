.roadmap-sec {
    position: relative;
    padding: 20px 0;
}

.roadmap-sec .col-12 {
    padding: 0;
}

.roadmap-sec .roadmap-inner {
    position: relative;
}

.roadmap-sec .roadmap-inner img {
    width: 100%;
    object-fit: fill;
}

.session-sec {
    position: relative;
    background-color: #000;
    padding: 30px 0 0;
}

.session-sec .session-title-area {
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
}

.session-sec .session-title-area img {
    width: 600px;
}

.session-sec .col {
    flex-basis: auto;
    max-width: 20%;
}

.session-sec .nft-box {
    position: relative;
    border-radius: 0;
    padding: 0;
    border: none;
    transition: all 0.8s ease-in-out;
    text-align: center;
}
.session-sec .nft-box:hover{
    animation: bounce2 2s ease infinite;
}
.session-sec .nft-box img {
    width: 70%;
    margin: 0 auto;
    text-align: center;
}
@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}

.crypto-sec {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    padding: 30px 0 12px;
    background-color: #000;
}

.crypto-sec .session-title-area {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.crypto-sec .session-title-area img {
    width: 500px;
}
.crypto-sec .crypt-box {
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out;
}

.crypto-sec .crypt-box img {
    width: 500px;
    transition: all 0.3s ease-in-out;
}

.crypto-sec .crypt-box:hover img {
    transition: all 0.3s ease-in-out;
    animation: spin 3s infinite linear;
}


@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
.roadmap-sec.style-two {
    padding: 0;
}

.roadmap-timeline-sec {
    position: relative;
    background-color: #000;
    padding: 30px 0;
}

.roadmap-timeline-sec .timeline-inner {
    position: relative;
    margin: 0 auto;
    text-align: center;
    width: 100%;
}

.roadmap-timeline-sec .timeline-inner img {
    width: 91%;
    margin: 0 auto;
}
.button-sec {
    position: relative;
    padding: 80px 0;
    text-align: center;
}

.button-sec .btn-style-three {
    background-color: #009600;
    color: #fff;
    padding: 11px 37px;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
    border: 1px solid #009600;
}

.button-sec .btn-style-three:hover {
    background-color: transparent;
    color: #009600;
}
.nft-section {
    position: relative;
    padding: 40px 0 20px;
    background-color: #000;
    margin: 20px 0;
}

.nft-section .session-title-area {
    width: 100%;
    margin: 0 auto 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.nft-section .session-title-area img {
    width: 159px;
    display: block;
}

.nft-section .session-title-area img.sec-heading {
    width: 586px;
}

.nft-section .crypt-box {
    position: relative;
    padding: 0 85px 20px;
    width: 100%;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.nft-section .crypt-box img {
    margin-bottom: 23px;
}

.nft-section .crypt-box h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
}

.nft-section .crypt-box h5 {
    font-size: 17px;
    color: #fff;
    font-weight: 600;
}
.nft-section .crypt-box:hover {
    transform: scale(1.1);
}

@media(max-width: 1600px){
}

@media(max-width: 1366px){
    .crypto-sec .crypt-box img {
        width: 390px;
    }
    .nft-section .crypt-box {
        padding: 0 65px 20px;
    }
}

@media(max-width: 1200px){
    .nft-section .crypt-box {
        padding: 0 45px 20px;
    }
}


@media(max-width: 1099px){
    .roadmap-sec .roadmap-inner img {
        height: 100%;
    }
    .session-sec .nft-box img {
        width: 100%;
    }
    .nft-section .crypt-box {
        padding: 0 25px 20px;
    }
}

@media(max-width: 991px){
    .session-sec .row {
        justify-content: center;
    }
    .session-sec .col {
        max-width: 33.33%;
    }
    .crypto-sec .crypt-box img {
        width: 70%;
    }
}

@media(max-width: 480px){
    .session-sec .col {
        max-width: 50%;
    }
}