.privacy-sec {
    position: relative;
    padding: 20px 10px;
}

.privacy-sec h3 {
    font-size: 27px;
    margin-bottom: 10px;
    font-weight: 700;
}

.privacy-sec .privacy-box {
    position: relative;
    margin-bottom: 34px;
}

.privacy-sec .privacy-box p {
    position: relative;
}

.privacy-sec .privacy-box ul {
    margin-left: 15px;
    margin-bottom: 30px;
}

.privacy-sec .privacy-box ul li {
    margin-bottom: 12px;
}

.privacy-sec .privacy-box h4 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 19px;
}

.privacy-sec .privacy-box .box-inner {
    margin-left: 30px;
}

.privacy-sec .privacy-box ul li ul {
    margin-top: 20px;
}

@media(max-width: 500px){
    .privacy-sec h3{
        font-size: 22px;
    }
    .privacy-sec p,
    .privacy-sec .privacy-box p {
        font-size: 14px;
    }
    .privacy-sec .privacy-box h4 {
        font-size: 19px;
    }
    .privacy-sec {
        position: relative;
        padding: 20px 0;
    }
}