/* .cheeba-nft-collection-nav.sticky-nav {
    position: fixed;
    background-color: #000;
    top: 0;
} */

.cheeba-nft-collection-nav .navbar-nav .social-menu {
  position: relative;
  display: flex;
  margin-left: 30px;
  margin-top: 6px;
  list-style: none;
}



.cheeba-nft-collection-nav {
  position: relative;
  top: 0;
  z-index: 999;
  width: 100%;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  padding: 17px 0 10px;
}

.cheeba-nft-collection-nav.sticky-nav .inner-container .searchbox a.btn-style-two,
.cheeba-nft-collection-nav.sticky-nav .inner-container .searchbox a.btn-style-one:hover {
  color: #202020;
  border-color: #202020;
}

.cheeba-nft-collection-nav.sticky-nav {
  position: fixed;
  top: 0;
  padding: 10px 0 0;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0 0 0 / 50%);
}

.cheeba-nft-collection-nav .inner-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  position: relative;
  width:90%;
}

.cheeba-nft-collection-nav .navbar-nav .social-icon .icon-area {
  position: relative;
  margin: 0 5px;
}

.cheeba-nft-collection-nav .navbar-nav .social-icon .icon-area i img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.landing-dogfather .landing-marquee .marquee-container a {
  margin-right: 15px;
}

.cheeba-nft-collection-nav .inner-container .navbar-brand {
  width: 420px;
  padding: 0;
  position: relative;
}

.navbar .button-head button .signIn-loader {
  padding: 0px 40px;
}

/* .navbar .button-head span:hover,
.navbar .button-head button:hover {  
background-color: #543c90;
} */
.img-trust-wallet {
  border-radius: 12px;
  margin-right: 26px;
}

.signup-modal .connect-wallat-signup .text-meta-mask-signup h5 span img {
  width: 5% !important;
}


.cheeba-nft-collection-nav .navbar-nav .connectbutton {
  background: no-repeat;
  border: none;
  color: #d0a535;
  text-decoration: underline;
}

.cheeba-nft-collection-nav .navbar-collapse {
  justify-content: start;
  width: 65%;
  left: 0;
  position: relative;
  padding-left: 0;
}

.cheeba-nft-collection-nav .navbar-nav li {
  margin-right: 30px;
  transition: all 0.3s ease-in-out;
  padding-top: 0;
}

.cheeba-nft-collection-nav .navbar-nav li.nav-img {
  margin-right: 0;
  margin-left: 10px;
  padding-top: 11px;
}

.cheeba-nft-collection-nav .navbar-nav li.nav-img {
  margin-right: 0;
  margin-left: 10px;
  padding-top: 11px;
}

.cheeba-nft-collection-nav .navbar-nav li.nav-img .right-img {
  width: 178px;
}

.cheeba-nft-collection-nav .navbar-nav li a {
  font-size: 16px;
  display: block;
  color:  #515d75;
  padding: 0 0 12px !important;
  font-family: "Roboto", sans-serif;
  transition: all 0.3s ease-in-out;
  position: relative;
  font-weight: 600;
}

.cheeba-nft-collection-nav .navbar-nav li a:hover::before,
.cheeba-nft-collection-nav .navbar-nav li a.active::before {
  opacity: 1;
  visibility: visible;
  left: 0;
}

.cheeba-nft-collection-nav .navbar-nav li a:hover,
.cheeba-nft-collection-nav .navbar-nav li a.active {
  color: #1f2130;
}
.cheeba-nft-collection-nav .navbar-nav li a.nav-green {
  font-family: 'Jumble';
  font-size: 25px;
  margin-bottom: 0;
  padding: 0 !important;
   /* width and color */
  color: transparent;
  text-shadow: 0px 0px 2px #00000020;
  background: #0bad06;
  background: linear-gradient(to right, #0bad06 0%, #0bad06 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: -4px;
}
.cheeba-nft-collection-nav .navbar-nav li a.nav-green img {
  width: 222px;
}
.cheeba-nft-collection-nav .navbar-nav li a.nav-green:hover {
  text-shadow: 1px 1px 2px #00000050;
}

.cheeba-nft-collection-nav .inner-container .searchbox {
  position: absolute;
  margin-bottom: 0;
  top: -3px;
  right: 0;
  display: flex;
  align-items: center;
}
.cheeba-nft-collection-nav .inner-container .searchbox li a {
  display: block;
}

.cheeba-nft-collection-nav .inner-container .searchbox .nav-item .nav-link {
    padding-bottom: 0 !important;
}
.cheeba-nft-collection-nav .inner-container .searchbox li a.btn-style-one,
.cheeba-nft-collection-nav .inner-container .searchbox li .tw-connect-wallet,
.cheeba-nft-collection-nav .inner-container .searchbox li .tw-connected-wallet {
  background-color: #009600;
  border:1px solid #009600;
  color: #fff;
  padding: 9px 25px !important;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}
.cheeba-nft-collection-nav .inner-container .searchbox li a.btn-style-one:hover,
.cheeba-nft-collection-nav .inner-container .searchbox li .tw-connect-wallet:hover,
.cheeba-nft-collection-nav .inner-container .searchbox li .tw-connected-wallet:hover {
    background-color: #006E00;
    color: #fff !important;
    border:1px solid #006E00;
}
.cheeba-nft-collection-nav .inner-container .searchbox li .tw-connected-wallet {
  zoom: 0.8;
}
.cheeba-nft-collection-nav .inner-container .searchbox li .tw-connected-wallet .tw-connected-wallet__balance.css-1kgliau{
  color: #fff !important;
}
.cheeba-nft-collection-nav .inner-container .searchbox li .tw-connected-wallet .tw-connected-wallet__address{
  font-size: 12px;
  transition: all 0.3s ease-in-out;
}
.cheeba-nft-collection-nav .inner-container .searchbox li .tw-connected-wallet:hover 
.tw-connected-wallet__address{
  color: #fff;
}
.cheeba-nft-collection-nav .inner-container .searchbox li:hover .tw-connected-wallet__balance{
  color: #fff;
}
.cheeba-nft-collection-nav .inner-container .searchbox a {
  margin-left: 10px;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.05, 1.05);
  }

  100% {
    transform: scale(1, 1);
  }
}

.cheeba-nft-collection-nav .inner-container .searchbox .btn-style-one .icon {
  position: relative;
  padding-left: 6px;
  top: 0px;
}

.cheeba-nft-collection-nav .navbar-collapse .close-btn-area {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: rgba(0 0 0 / 0.9);
  transition: all 0.4s ease-in-out;
}

.cheeba-nft-collection-nav .navbar-collapse.show .close-btn-area {
  left: 0;
}

.cheeba-nft-collection-nav .navbar-collapse .close-btn-area .close-button {
  background-color: transparent;
  padding: 0;
  top: 25px;
  right: 28px;
  background: transparent;
  transition: all 0.4s ease-in-out;
}

.cheeba-nft-collection-nav .navbar-collapse .close-btn-area .close-button i {
  font-size: 50px;
  font-style: normal;
}

.cheeba-nft-collection-nav .price-sec {
  position: absolute;
  right: 8%;
  width: 347px;
  top: 95px;
}

.cheeba-nft-collection-nav .price-sec ul {
  position: relative;
  display: flex;
  justify-content: space-between;
  list-style: none;
  background-color: rgba(244 245 252 / 85%);
  border-radius: 12px;
}

.cheeba-nft-collection-nav .price-sec ul li {
  width: 50%;
  padding: 0 15px;
  margin: 20px 0;
}

.cheeba-nft-collection-nav .price-sec ul li h4 {
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  color: #04061D;
  margin-bottom: 15px;
}

.cheeba-nft-collection-nav .price-sec ul li:nth-child(1) {
  border-right: 1px solid #04061D;
}

.cheeba-nft-collection-nav .price-sec ul li .price {
  display: flex;
}

.cheeba-nft-collection-nav .price-sec ul li .price .icon {
  margin-right: 10px;
  position: relative;
  top: -3px;
}

.cheeba-nft-collection-nav .price-sec ul li .price p {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
  color: #04061D;
}

.navbar-expand-lg .navbar-collapse .navbar-nav {
  width: 100%;
}


.navbar-expand-lg .navbar-collapse .mobile-menu-logo {
  display: none;
}

.cheeba-nft-collection-nav .navbar-nav li a .icon {
  margin-right: 10px;
}

.cheeba-nft-collection-nav .navbar-nav li a .icon svg {
  position: relative;
  top: -2px;
}

.cheeba-nft-collection-nav .navbar-nav li a .icon svg path {
  stroke: #293A49;
}

.cheeba-nft-collection-nav .navbar-nav li a:hover .icon svg path,
.cheeba-nft-collection-nav .navbar-nav li a.active .icon svg path {
  stroke: #49AEFD;
}

.cheeba-nft-collection-nav .inner-container .searchbox .dropdown {
  position: relative;
  width: 120px;
}

.cheeba-nft-collection-nav .inner-container .searchbox .dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
}

.cheeba-nft-collection-nav .inner-container .searchbox .dropdown .dropdown-toggle img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}


.cheeba-nft-collection-nav .inner-container .searchbox .dropdown .dropdown-toggle p {
  margin-bottom: 0;
  margin-left: 15px;
  font-size: 13px;
}

.cheeba-nft-collection-nav .inner-container .searchbox .dropdown .dropdown-menu {
  padding: 0;
  min-width: 117px;
}

.cheeba-nft-collection-nav .inner-container .searchbox .dropdown .dropdown-menu .dropdown-item {
  margin: 0;
  font-size: 13px;
}
.cheeba-nft-collection-nav .navbar-nav li .shop-icon {
  display: block;
  position: relative;
  margin-right: 0;
  top: 5px;
  background: transparent;
  border: none;
}

.cheeba-nft-collection-nav .navbar-nav li .shop-icon img {
  width: 24px;
}

.cheeba-nft-collection-nav .navbar-nav li .shop-icon .counter-icon {
  position: absolute;
  right: -7px;
  top: 1px;
  width: 14px;
  height: 14px;
  background-color: #2f5bf9;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  text-align: center;
  font-style: normal;
}

@media(max-width: 1710px) {
  .cheeba-nft-collection-nav .navbar-nav li {
    margin-right: 20px;
  }

  .cheeba-nft-collection-nav .navbar-nav li a .icon {
    margin-right: 5px;
  }
}

@media(max-width: 1610px) {
  .cheeba-nft-collection-nav.sticky-nav .inner-container .searchbox {
    top: -4px;
  }

  .cheeba-nft-collection-nav.sticky-nav {
    padding: 10px 0 0;
  }
}

@media(max-width: 1550px) {

  .cheeba-nft-collection-nav .navbar-nav li {
    margin-right: 15px;
  }
}

@media(max-width: 1450px) {
  
  .cheeba-nft-collection-nav .inner-container{
    width: 89%;
  }
  .cheeba-nft-collection-nav .navbar-collapse{
    width: 60%;
  }
}

@media(max-width:1300px) {
  .cheeba-nft-collection-nav .navbar-collapse{
    width: 72%;
  }
  .cheeba-nft-collection-nav .navbar-collapse{
    width: 50%;
  }
}

@media(max-width: 1240px) {}

@media(max-width: 1199px) {
  .cheeba-nft-collection-nav .inner-container .navbar-brand {
    width: 320px;
  }
  .cheeba-nft-collection-nav .navbar-nav li a {
    font-size: 12px;
  }

  .cheeba-nft-collection-nav .navbar-nav li {
    margin-right: 12px;
  }

  .cheeba-nft-collection-nav .inner-container {
    justify-content: center;
  }

}

@media(max-width: 1055px) {
  .cheeba-nft-collection-nav .navbar-nav li {
    margin-right: 17px;
  }
}

@media(max-width: 991px) {
  .navbar .button-head {
    padding-right: 0px;
  }

  .cheeba-nft-collection-nav .navbar-nav li a .icon {
    margin-right: 10px;
    display: inline-block;
  }

  .cheeba-nft-collection-nav .navbar-nav li a {
    padding-bottom: 0 !important;
    margin-bottom: 0;
    border: none !important;
  }
  .navbar-expand-lg .navbar-collapse .mobile-menu-logo {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    border-bottom: 1px solid rgba(137, 151, 163, 0.1);
    margin-bottom: 20px;
  }

  .cheeba-nft-collection-nav .navbar-nav li {
    margin-right: 0;
    border-bottom: 1px solid rgba(137, 151, 163, 0.1);
    padding-bottom: 15px;
    margin-bottom: 15px;
    padding-left: 30px;
  }

  .navbar-expand-lg .navbar-collapse .mobile-menu-logo .navbar-brand {
    padding: 0;
  }

  .navbar-expand-lg .navbar-collapse .mobile-menu-logo .navbar-toggler {
    position: relative;
    top: auto;
    right: 0;
    transform: translateY(0);
  }

  .cheeba-nft-collection-nav .inner-container {
    justify-content: start;
  }

  .cheeba-nft-collection-nav .navbar-collapse.show .navbar-nav .social-icon {
    margin-top: 30px;
    text-align: center;
  }

  .cheeba-nft-collection-nav .price-sec {
    right: 2%;
    top: 52px;
  }

  .cheeba-nft-collection-nav .inner-container {
    margin: 0;
  }

  .navbar .nav-link {
    padding-right: 0px !important;
  }

  .cheeba-nft-collection-nav #navbarSupportedContent {
    margin-top: 0;
    margin-left: 0;
  }

  .cheeba-nft-collection-nav .inner-container .navbar-toggler {
    position: absolute;
    top: 50%;
    right: 50px;
    color: #368BF3;
    border-radius: 0;
    padding: 0;
    margin: 0;
    transform: translateY(-50%);
  }
  .cheeba-nft-collection-nav .inner-container .navbar-toggler .icon {
    width: 30px;
    display: block;
  }
  .cheeba-nft-collection-nav .inner-container .navbar-toggler .nav-links-btn {
    position: absolute;
    color: #fff;
    border-radius: 0;
    transform: translateY(-50%);
  }

  .cheeba-nft-collection-nav .navbar-collapse .navbar-nav {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.5s ease-in-out;
  }

  .cheeba-nft-collection-nav .inner-container .searchbox {
    display: block;
    position: relative;
    padding: 25px 30px;
    text-align: center;
  }

  .cheeba-nft-collection-nav .navbar-collapse {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0;
    padding: 0;
    z-index: 99;
    height: 100%;
    background-color: #F1F4FD;
  }
  .cheeba-nft-collection-nav .inner-container .searchbox a.btn-style-two, .cheeba-nft-collection-nav .inner-container .searchbox a.btn-style-one:hover {
    color: #202020;
    border-color: #202020;
  } 
  .cheeba-nft-collection-nav .navbar-nav .social-menu {
    margin-top: 6px;
    justify-content: center;
  }


  .cheeba-nft-collection-nav .inner-container .navbar-toggler {
    z-index: 9;
  }
  .cheeba-nft-collection-nav .inner-container .nav-links-btn {
    position: relative;
    top: 3% !important;
    right: 0px !important;
    text-align: left;
  }

  .cheeba-nft-collection-nav .searchbox {
    position: relative;
    margin-bottom: 0;
    top: 10px;
  }
  .cheeba-nft-collection-nav .inner-container {
    width: 100%;
  }
  .cheeba-nft-collection-nav .inner-container .searchbox {
    display: block;
    position: relative;
    padding: 0;
    text-align: left;
    align-items: center;
    justify-content: center;
  }
  .cheeba-nft-collection-nav .inner-container .searchbox li a.btn-style-one {
    text-align: center;
    margin: 0 18px;
  }
  .cheeba-nft-collection-nav .inner-container .searchbox a {
    margin-left: 0;
  }
  .cheeba-nft-collection-nav .navbar-nav li .shop-icon .counter-icon{
    right: auto;
    top: -4px;
  }
}

@media(max-width: 576px) {
  .cheeba-nft-collection-nav .navbar-brand {
    width: 208px;
  }
  .cheeba-nft-collection-nav .inner-container .searchbox {
    display: block;
  }

  .cheeba-nft-collection-nav .navbar-brand img {
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
  }

  .landing-dogfather .landing-marquee .marquee-container {
    font-size: 14px;
  }

  .cheeba-nft-collection-nav .price-sec {
    width: 91%;
  }
  .cheeba-nft-collection-nav .inner-container .navbar-brand {
    width: 220px;
  }
  .cheeba-nft-collection-nav .inner-container .navbar-toggler .icon{
    width: 25px;
  }
}


@media (max-width: 380px) {


  .cheeba-nft-collection-nav .inner-container .navbar-toggler {
    right: 10px;
  }
}

/* @media(max-width: 768px){
.navbar .button-head button{
  margin-left: 20px;
}
} */