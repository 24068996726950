

.cheeba-nft-collection-page .benifits-sec.planter-page-benifits .benifits-inner .benifit-list.benifit-one li:nth-child(2), 
.cheeba-nft-collection-page .benifits-sec.planter-page-benifits .benifits-inner .benifit-list.benifit-one li:nth-child(4){
    width: 30%;
}

.cheeba-nft-collection-page .banner-sec.style-two.planter-page .row {
    align-items: center;
}

.cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner.center-box {
    position: relative;
}

.cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner.center-box h3 {
    line-height: 39px;
}

.cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner.right-list-box {
    text-align: left;
}

.cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner.right-list-box ul {
    margin-bottom: 0;
    margin-top: 12px;
    list-style: none;
}

.cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner.right-list-box ul li {
    position: relative;
    margin-bottom: 16px;
    padding-left: 32px;
    color: #fff;
}

.cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner.right-list-box ul li .icon {
    position: absolute;
    left: 0;
    width: 20px;
}

.cheeba-planter-page .sec-title.green-title h2 {
    margin-bottom: 21px;
}

.cheeba-planter-page .sec-title.green-title h3 {
    color: #006E00;
}
.cheeba-nft-collection-page .banner-sec.style-two.planter-page .inner-container .nft-box-banner.center-box.planter-box-nft .img-box img {
    width: 95%;
}

