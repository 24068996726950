.product-detail-sec .right-content .detail-area-planter {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
}

.product-detail-sec .right-content .detail-area-planter .img-box {
    width: 40%;
    padding-right: 20px;
}

.product-detail-sec .right-content .detail-area-planter .text-box {
    width: 60%;
    padding-left: 30px;
}

.product-detail-sec .right-content .detail-area-planter .text-box p {
    font-weight: 400;
}

.product-detail-sec .right-content .detail-area-planter .text-box ul {
    margin: 0 0 30px 0;
    list-style: none;
}

.product-detail-sec .right-content .detail-area-planter .text-box ul li {
    margin-bottom: 10px;
    padding-left: 35px;
    position: relative;
}

.product-detail-sec .right-content .detail-area-planter .text-box ul li .icon {
    position: absolute;
    left: 0;
    width: 21px;
}