.cheeba-rewards-page{
    min-height: 100vh;
}
.reward-page .row.jusc {
    justify-content: center;
}

.planet-sec.reward-page .text-block {
    text-align: center;
    margin-bottom: 27px;
}

.planet-sec.reward-page .text-block p {
    color: #7d8597;
    font-weight: 500;
}
h4.not-found {
    position: relative;
    padding: 50px 0;
    border: 1px solid #cccccc40;
    border-radius: 18px;
    width: 100%;
    text-align: center;
}
.planet-sec.reward-page .text-block.style-two {
    margin-top: 20px;
}

.planet-sec.reward-page .text-block h3 {
    font-size: 24px;
    color: #1f2130;
    font-weight: 600;
}

.planet-sec.reward-page .text-block button,
.planet-sec.reward-page .text-block a {
    min-width: 250px;
}
.planet-sec.reward-page {
    border-bottom: 2px solid #f0f0f0;
    padding-top: 15px;
}
.planet-sec.reward-page .text-block h3 .icon {
    display: inline-block;
    width: 23px;
    position: relative;
    top: -2px;
    margin-right: 15px;
}
.cheeba-nft-collection-page .banner-sec.style-two .inner-container .title-area h3 {
    margin-bottom: 0;
    color: #fff;
    font-size: 19px;
    font-weight: 600;
    margin-top: 30px;
}
.Select.is-open { position: relative; z-index: 1000; }
.cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner.center-box.rewards-box-2 .img-box img {
    width: 400px;
}
.cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner.center-box.img-reward-center .img-box img {
    width: 526px;
}
.cheeba-nft-collection-page .banner-sec.style-two.planter-page .row.rwards-page {
    align-items: flex-start;
}
.planet-sec.reward-page .text-block button .icon, 
.planet-sec.reward-page .text-block a .icon {
    display: inline-block;
    width: 20px;
    position: relative;
    top: -2px;
    margin-right: 10px;
}
.allowlist-sec.claim-from-sec .form-box p {
    font-size: 15px;
}
.claim-from-sec h3 {
    margin-bottom: 7px !important;
}
.planet-sec.reward-page .text-block.style-three h3 {
    color: #009600;
}
.claim-from-sec .form-box p {
    color: #7d8597;
    margin-bottom: 24px;
}

.claim-from-sec .form-box .group-form .btn-style-one {
    width: 100%;
    position: relative;
    z-index: auto;
}
.allowlist-sec .form-box form .group-form .MuiFormControl-root .css-13cymwt-control,
.card-info.allowlist-sec .group-form .MuiFormControl-root .css-13cymwt-control{
    border: 1px solid #93939350 !important;
    width: 100% !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    text-align: left !important;
}

.allowlist-sec .form-box form .group-form input,
.card-info.allowlist-sec .group-form input{
    padding: 10px 14px !important;
    width: 100% !important;
    border-radius: 8px !important;
    border-color: #93939380 !important;
}
.card-info.allowlist-sec .group-form{
    text-align: left;
}
.css-t3ipsp-control:hover{
    border: 1px solid #93939350 !important;
    box-shadow: none !important;

}
.allowlist-sec .form-box form .group-form .MuiFormControl-root .css-13cymwt-control #react-select-2-placeholder,
.card-info.allowlist-sec .group-form .MuiFormControl-root .css-13cymwt-control #react-select-2-placeholder{
    color: #00000060;
}
.cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner.center-box.rewards-box .img-box img {
    width: 71%;
}
.cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner.center-box.rewards-box h3 {
    font-size: 21px;
    line-height: 35px;
}

.cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner.center-box.rewards-box-2 h3{
    font-size: 21px;
    line-height: 35px;
}
.cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner.center-box.rewards-box-2 h3 span {
    font-size: 16px;
}