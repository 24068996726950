.about-sec {
    position: relative;
    padding: 25px 140px;
}

.nft-box {
    position: relative;
    border: 1px solid #939393;
    border-radius: 11px;
    margin-bottom: 30px;
    transition: all 0.3s ease-in-out
}
.nft-box:hover {
    box-shadow: 0 0 10px rgba(0 0 0 / 20%);
}
.nft-box .image-box {
    background-color: #f0f1f3;
    border-radius: 11px 11px 0 0;
    text-align: center;
    padding: 10px 20px;
}

.nft-box .text-box {
    padding: 20px;
    text-align: center;
}

.nft-box .text-box h5 {
    font-size: 18px;
    font-weight: 600;
    color: #1f2130;
    margin-bottom: 0;
}
.logos-sec {
    position: relative;
}

.logos-sec .logos-area {
    text-align: center;
}

.logos-sec .logos-area h2 {
    font-size: 27px;
    font-weight: 600;
    color: #1f2130;
}

.logos-sec .logos-area img {
    width: 60%;
}

.faqs-sec #accordion {
    position: relative;
}

.faqs-sec #accordion .card {
    position: relative;
    background-color: transparent;
    border: none;
    padding-left: 0;
    margin-bottom: 20px;
}

.faqs-sec #accordion .card .card-header {
    border-radius: 0;
    background-color: transparent;
    padding: 0;
    border: none;
}

.faqs-sec #accordion .card .card-header h5 {
    width: 100%;
}

.faqs-sec #accordion .card .card-header h5 .btn.btn-link {
    width: 100%;
    text-align: left;
    color: #1f2130;
    font-size: 18px;
    text-decoration: none !important;
    position: relative;
    transition: all 0.3s ease-in-out;
    border: 1px solid #e5e5ea;
    border-radius: 0;
    padding: 10px 41px;
    font-weight: 700;
}
.slick-slider.slider-nav-thumbnails {
    margin-bottom: 30px;
}

.faqs-sec #accordion .card .card-header h5 .btn.btn-link:before {
    content: "";
    position: absolute;
    left: 15px;
    top: 10px;
    width: 13px;
    height: 18px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url('../../static/images/bottom-arrow.png');
    transform: rotate(-180deg);
    transition: all 0.3s ease-in-out;
}

.faqs-sec #accordion .card .card-header h5 .btn.btn-link.collapsed:before {
    transform: rotate(-90deg);
    top: 14px;
}

.faqs-sec #accordion .card .card-header h5 .btn.btn-link span {
    display: block;
    font-size: 14px;
    color: #9F9F9F;
}

.faqs-sec #accordion .card .collapse {
    position: relative;
    padding: 0;
}

.faqs-sec #accordion .card .collapse .card-body {
    position: relative;
    padding: 17px;
    margin-top: 0;
    border: 1px solid #e5e5ea;
    border-top: none;
    font-size: 14px;
    color: #5b667c;
    font-family: "Roboto", sans-serif;
    font-style: normal;
}
.faqs-sec #accordion .card .collapse .card-body ul {
    margin-bottom: 0;
    margin-left: 16px;
}

.faqs-sec #accordion .card .collapse .card-body ul li {
    margin-top: 9px;
}
.faqs-sec #accordion .card .collapse .card-body .traits-column {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.faqs-sec #accordion .card .collapse .card-body .traits-column .traits-box {
    width: 31%;
    background-color: #f8f8f8;
    margin-left: 10px;
    margin-bottom: 10px;
    text-align: center;
    padding: 10px;
    min-height: 84px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.faqs-sec #accordion .card .collapse .card-body .traits-column .traits-box p {
    font-size: 12px;
    margin-bottom: 0;
    color: #5b667c;
    width: 100%;
}

.faqs-sec #accordion .card .collapse .card-body .traits-column .traits-box h4 {
    font-size: 13px;
    margin: 5px 0;
    color: #1f2130;
    font-weight: 600;
    width: 100%;
}

.faqs-sec #accordion .card .collapse .card-body .traits-column .traits-box h4 span {
    color: #5b667c;
}
.faqs-sec #accordion .card .collapse .card-body .detial-box {
    position: relative;
    padding: 10px 10px 0;
}

.faqs-sec #accordion .card .collapse .card-body .detial-box ul {
    list-style: none;
    margin-bottom: 0;
}

.faqs-sec #accordion .card .collapse .card-body .detial-box ul li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
    color: #5b667c;
}
.faqs-sec {
    position: relative;
    padding: 25px 0;
}
.faqs-sec #accordion .card .collapse .card-body .detial-box ul li span {
    font-weight: 500;
    color: #1f2130;
}

.faqs-sec #accordion .card .collapse .card-body .detial-box ul li span.style-two {
    color: #5b667c;
}

.faqs-sec #accordion .card .collapse .card-body .detial-box p {
    font-size: 14px;
    margin-bottom: 15px;
    color: #5b667c;
    font-weight: 500;
}
.cheeba-nft-collection-page .banner-sec.style-two {
    position: relative;
}

.cheeba-nft-collection-page .banner-sec.style-two .inner-container {
    padding-bottom: 10px;
}

.cheeba-nft-collection-page .banner-sec.style-two .inner-container .title-area {
    position: relative;
    padding: 20px 0;
    text-align: center;
}

.cheeba-nft-collection-page .banner-sec.style-two .inner-container .title-area h2 {
    margin-bottom: 0;
    color: #fff;
    font-size: 50px;
    font-weight: 600;
}

.cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner {
    position: relative;
    width: 100%;
    text-align: center;
}

.cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner .img-box {
    position: relative;
    margin-bottom: 20px;
}

.cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner .img-box img {
    width: 66%;
}

.cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner h3 {
    font-size: 23px;
    color: #fff;
    font-weight: 700;
}
.cheeba-nft-collection-page .banner-sec.style-two .inner-container .img-box-banner .nft-box-banner .img-box img {
    width: 88%;
}
.benifits-sec {
    position: relative;
    padding: 24px 0 22px;
}

.benifits-sec .benifits-inner {
    border: 1px solid #000;
    padding:25px 25px 13px;
    border-radius: 15px;
}

.benifits-sec .benifits-inner .title-area {
    position: relative;
    text-align: center;
    margin-bottom: 30px;
}

.benifits-sec .benifits-inner .title-area h2 {
    font-size: 27px;
    font-weight: 700;
}

.benifits-sec .benifits-inner .benifit-list {
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
.benifits-sec .benifits-inner .benifit-list.benifit-one li {
    width: 25%;
}
.benifits-sec .benifits-inner .benifit-list.benifit-two li {
    width: 33.33%;
}

.benifits-sec .benifits-inner .benifit-list.benifit-two li.empty {
    width: 16%;
}
.benifits-sec .benifits-inner .benifit-list.benifit-two {
    justify-content: center;
    margin: 0 0 0 50px;
}
.benifits-sec .benifits-inner .benifit-list.benifit-one li.empty {
    width: 14%;
}
.benifits-sec .benifits-inner .benifit-list li {
    width: 50%;
    margin-bottom: 19px;
    position: relative;
    padding-left: 45px;
    padding-right: 31px;
    font-size: 17px;
    padding-bottom: 11px;
}

.benifits-sec .benifits-inner .benifit-list li .icon {
    position: absolute;
    left: 0;
    top: 35%;
    transform: translateY(-50%);
}
.benifits-sec.benifits-sec-green .benifits-inner {
    border: 1px solid #009600;
}

.benifits-sec.benifits-sec-green .benifits-inner h2 {
    color: #009600;
}
.benifits-sec .benifits-inner .benifit-list li .icon img {
    width: 20px;
}
.benifits-sec.style-two .benifits-inner {
    background-color: #000;
}

.benifits-sec.style-two .benifits-inner .title-area h2,
.benifits-sec.style-two .benifits-inner .benifit-list li {
    color: #fff;
}
.benifits-sec .benifits-inner .benifit-list li:nth-child(even) {
    padding-right: 0;
}
.benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(3), .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(1) {
    width: 54%;
}

.benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(1), .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(3) {
    width: 44%;
}

.benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(2), .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(4) {
    width: 24%;
}

.benifits-sec .benifits-inner .benifit-list.benifit-two li:nth-child(2), .benifits-sec .benifits-inner .benifit-list.benifit-two li:nth-child(4), .benifits-sec .benifits-inner .benifit-list.benifit-two li:nth-child(6), .benifits-sec .benifits-inner .benifit-list.benifit-two li:nth-child(8) {
    width: 47%;
}

.benifits-sec .benifits-inner .benifit-list.style-three {
    margin: 0  10px;
}
.benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(3), .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(1) {
    width: 54%;
}

.benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(1), .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(3) {
    width: 44%;
}

.benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(2), .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(4) {
    width: 24%;
}

.benifits-sec .benifits-inner .benifit-list.benifit-two li:nth-child(2), .benifits-sec .benifits-inner .benifit-list.benifit-two li:nth-child(4), .benifits-sec .benifits-inner .benifit-list.benifit-two li:nth-child(6), .benifits-sec .benifits-inner .benifit-list.benifit-two li:nth-child(8) {
    width: 47%;
}

.benifits-sec .benifits-inner .benifit-list.style-three {
    margin: 0  10px;
}

.benifits-sec .benifits-inner .benifit-list.benifit-two li:nth-child(even) {
    width: 40%;
}

.benifits-sec .benifits-inner .benifit-list.benifit-two li {
    width: 44%;
}
.benifits-sec .benifits-inner .benifit-list.benifits-three li .icon img {
    width: 27px;
}
.benifits-sec .benifits-inner .benifit-list.benifits-three {
    padding: 0 36px;
    margin-bottom: 0;
}
@media(max-width: 1680px){
    .benifits-sec .benifits-inner .benifit-list.benifit-one li {
        width: 31%;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-two li {
        width: 38.33%;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-two li.empty {
        width: 11%;
    }
}
@media(max-width: 1450px){
    .nft-box .text-box h5 {
        font-size: 16px;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-two li.empty {
        width: 4%;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-two li {
        width: 43%;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-one li {
        width: 37%;
    }
    .benifits-sec .benifits-inner .benifit-list li{
        font-size: 16px;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(2), .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(4){
        width: 26%;
    }
    .cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner h3{
        font-size: 20px;
    }
}
@media(max-width: 1280px){
    .about-sec {
        padding: 25px 80px;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-two li.empty {
        width: auto;
        display: none;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-two li {
        width: 50%;
    }
}

@media(max-width: 1199px){
    .about-sec {
        padding: 25px 40px;
    }
    .benifits-sec .benifits-inner .title-area h2{
        font-size: 20px;
    }
    .benifits-sec .benifits-inner .benifit-list li{
        font-size: 15px;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-one li {
        width: 50%;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-one li.empty {
        width: auto;
        display: none;
    }
}
@media(max-width: 1099px){
    .about-sec {
        padding: 25px 0px;
    }
    .logos-sec .logos-area h2 {
        font-size: 22px;
        font-weight: 600;
        color: #1f2130;
    }
    .logos-sec .logos-area img {
        width: 96%;
    }
    .faqs-sec #accordion .card .card-header h5 .btn.btn-link{
        font-size: 16px;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(2), .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(4) {
        width: 33%;
    }
}
@media(max-width: 991px){
    .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(2), 
    .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(4) {
        width: 50%;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(1), 
    .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(3) {
        width: 50%;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-two {
        justify-content: center;
        margin: 0;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-two li:nth-child(even) {
        width: 50%;
    }
    .cheeba-nft-collection-page .banner-sec.style-two .inner-container {
        padding-bottom: 10px;
        padding: 0 25px 25px !important;
    }
}
@media(max-width: 600px){
    .cheeba-nft-collection-page .banner-sec.style-two .inner-container .nft-box-banner h3{
        font-size: 18px;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-two li{
        width: 100% !important;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(2), 
    .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(4) {
        width: 100%;
    }
    .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(1), 
    .benifits-sec .benifits-inner .benifit-list.benifit-one li:nth-child(3) {
        width: 100%;
    }
    .cheeba-nft-collection-page .banner-sec.style-two .inner-container .title-area h2{
        font-size: 30px;
    }
    .benifits-sec .benifits-inner .benifit-list li,
    .benifits-sec .benifits-inner .benifit-list.benifit-one li,
    .benifits-sec .benifits-inner .benifit-list.benifit-two li{
        width: 100%;
        padding-right: 0;
    }
    .sec-title.green-title {
        padding: 20px;
    }
    .sec-title h2{
        font-size: 18px;
    }
    .sec-title.green-title h3 {
        font-size: 13px;
    }
}

@media(max-width: 400px){
    .benifits-sec .benifits-inner{
        padding: 20px 20px 0;
    }
    .benifits-sec .benifits-inner .title-area h2 {
        font-size: 18px;
    }
    .benifits-sec .benifits-inner .benifit-list li {
        font-size: 14px;
    }
}