@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

/* font-family: "Roboto", sans-serif;
font-family: "Roboto", sans-serif; */

@font-face {
  font-family: 'Jumble';
  src: url('../fonts/Jumble.eot');
  src: url('../fonts/Jumble.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Jumble.woff2') format('woff2'),
      url('../fonts/Jumble.woff') format('woff'),
      url('../fonts/Jumble.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-input-placeholder {
  color: inherit;
}

::-moz-input-placeholder {
  color: inherit;
}

::-ms-input-placeholder {
  color: inherit;
}

.auto-container {
  position: static;
  max-width: 90%;
  padding: 0px 15px;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.2em;
  font-family: 'Lato',
    sans-serif;
  font-weight: normal;
}

button:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
}

input:-internal-autofill-selected {
  background: transparent !important;
  background-color: transparent !important;
}

input:-webkit-autofill {
  -webkit-transition-delay: 9999999s !important;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

/* ========== Custom Scroll Styles =========== */

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E6E1DC;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #17090A;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #17090A;
}

/* ========== Main Modal Styles =========== */

.main-modal {
  max-width: 655px !important;
  top: 5%;
}
.bridge-modal {
  max-width: 80% !important;
  top: 5%;
}

.main-modal .modal-header,
.modal-footer {
  border: none !important;
}

.main-modal .modal-content {
  position: relative;
  padding: 43px 20px;
  border-radius: 12px;
  border: 1px solid #F5F5F5;
  background: #FFF;
  box-shadow: -2px 4px 2.2px 0px rgba(0, 0, 0, 0.04);
}


.main-modal .modal-content .modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 12px;
  z-index: 9;
}
.main-modal .modal-content .content-area {
  position: relative;
  padding: 40px 0;
  width: 100%;
  text-align: center;
}

.main-modal .modal-content .content-area h2 {
  color: #202020;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 166.667% */
  letter-spacing: 0.48px;
}

.main-modal .modal-content .content-area p {
  color: #6D6D6D;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.28px;
}

.main-modal .modal-content .content-area .modal-btn {
  height: 48px;
  padding: 13px 0px 13.667px 0px;
  border-radius: 60px;
  background: #156CF7;
  display: block;
  text-align: center;
  color: #fff;
  margin: 40px auto 0;
  width: 64%;
}
.main-modal .modal-content .modal-header .close {
  color: #000;
}

.main-modal .modal-content .modal-header .modal-logo {
  display: flex;
  justify-content: center;
}

.main-modal .modal-content .modal-header .modal-logo img {
  width: 35%;
  padding: 25px;
}
.post-detail-modal .modal-content {
  width: 100%;
  height: 100%;
  background: #fff !important;
  box-shadow: -6px -6px 12px rgba(188.06, 214.84, 255, 0.60) inset;
  border-radius: 24px;
}

.post-detail-modal {
  position: relative;
  width: 70% !important;
  max-width: 70% !important;
}
@media(max-width: 1850px) {
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box .roadmap-text-box {
    padding: 31px 16px 0;
    margin: 0 40px;
  }
}

@media(max-width: 1710px) {
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box .roadmap-text-box p {
    font-size: 14px;
  }
}

@media(max-width: 1600px) {
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box {
    width: 54%;
    margin-top: 94px;
  }

  section#roadmap {
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

@media(max-width: 1450px) {
  .auto-container {
    max-width: 90%;
  }
  .post-detail-modal {
      width: 80% !important;
      max-width: 80% !important;
      margin: 0 auto !important;
  }
}



@media(max-width: 1400px) {

  .cheeba-nft-collection-page .roadmap-sec .roadmap-box {
    width: 54%;
    margin-top: 15px;
  }
}

@media screen and (max-width: 1400px) and (max-height: 1050px) {
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box {
    margin-top: 152px;
  }
}

@media(max-width: 1370px) {
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box {
    margin-top: 7px;
  }
}

@media(max-width: 1300px) {
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box .roadmap-text-box p {
    font-size: 12px;
  }

  .cheeba-nft-collection-page .roadmap-sec .roadmap-box .roadmap-text-box h3 img {
    width: 67%;
  }
}

@media screen and (max-width: 1280px) and (max-height: 960px) {
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box {
    margin-top: 108px;
  }
}

@media screen and (max-width: 1280px) and (max-height: 800px) {
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box {
    margin-top: 48px;
  }
}

@media screen and (max-width: 1280px) and (max-height: 768px) {
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box {
    margin-top: 18px;
  }
}

@media(max-width: 991px) {

  .cheeba-nft-collection-page .roadmap-sec .left-content-box {
    margin-top: 235px;
    top: -153px;
    position: relative;
  }

  .cheeba-nft-collection-page .roadmap-sec .roadmap-box,
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box.style-two {
    width: 45%;
    margin-top: -81px;
  }

  .cheeba-nft-collection-page .roadmap-sec .right-content-box {
    justify-content: flex-start;
    position: relative;
    top: -78px;
  }

  .cheeba-nft-collection-page .roadmap-sec .right-content-box .roadmap-box.style-two {
    margin-top: 278px;
    top: -23px;
    margin-right: 0;
  }

  .cheeba-nft-collection-page .roadmap-sec .right-content-box .roadmap-box {
    margin-top: 196px;
    position: relative;
    top: 61px;
  }

  .cheeba-nft-collection-page .roadmap-sec .roadmap-box .roadmap-text-box h3 img {
    width: 47%;
  }
}

@media(max-width: 600px) {
  .bridge-modal {
    max-width: 100% !important;
    top: 5%;
  }
  .post-detail-modal {
    position: relative;
    width: 95% !important;
    max-width: 95% !important;
  }
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box,
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box.style-two {
    width: 41%;
    margin-top: -81px;
  }

  .cheeba-nft-collection-page .roadmap-sec .roadmap-box .roadmap-text-box {
    padding: 31px 16px 0;
    margin: 0px 0;
    position: relative;
    border-bottom: none;
  }
  .post-detail-modal .modal-body {
      padding: 0 !important;
  }
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box .roadmap-text-box:before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -13px;
    width: 70%;
    height: 2px;
    background-color: #ffffff20;
    transform: translateX(-53%);
  }

  .auto-container {
    max-width: 90%;
    padding: 0 15px;
  }

  .cheeba-nft-collection-page .roadmap-sec .roadmap-box .roadmap-text-box h3 img {
    width: 65%;
  }

  .cheeba-nft-collection-page .roadmap-sec .right-content-box .roadmap-box .roadmap-text-box {
    left: -6px;
  }

  .cheeba-nft-collection-page .roadmap-sec .roadmap-box {
    height: 226px;
  }

  .cheeba-nft-collection-page .roadmap-sec .roadmap-box.style-two {
    margin-left: 76px;
  }

  .cheeba-nft-collection-page .roadmap-sec .right-content-box {
    top: -84px;
  }
}

/* @media(max-height: 960px) {
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box {
    margin-top: 108px;
  }
} */

@media(max-width: 555px) {

  .cheeba-nft-collection-page .roadmap-sec .roadmap-box,
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box.style-two {
    width: 38%;
    margin-top: -81px;
  }
}

@media(max-width: 480px) {
  .auto-container {
    max-width: 90%;
  }

  .cheeba-nft-collection-page .roadmap-sec .roadmap-box,
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box.style-two {
    width: 49%;
    margin-top: -81px;
  }

  .cheeba-nft-collection-page .roadmap-sec .roadmap-box.style-two {
    margin-left: 0;
  }

  .cheeba-nft-collection-page .roadmap-sec .roadmap-box .roadmap-text-box {
    position: relative;
    padding: 31px 25px 0;
    border-bottom: none;
    margin: 0 22px;
    left: 4px;
  }

  .cheeba-nft-collection-page .roadmap-sec .roadmap-box .roadmap-text-box h3 img {
    width: 100%;
  }

  .cheeba-nft-collection-page .roadmap-sec .roadmap-box .roadmap-text-box p {
    font-size: 10px;
  }
}

@media(max-width: 480px) {
  .cheeba-nft-collection-page .roadmap-sec .roadmap-box .roadmap-text-box {
    position: relative;
    padding: 31px 25px 0;
    border-bottom: none;
    margin: 0 0;
    left: 4px;
  }
}