.coming-soon-page {
    position: relative;
    background-image: url('../../static/images/coming-soon.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    height: 100vh;
    overflow: hidden;
}
.coming-soon-page .top-cutdown-area .left-logo-area {
    position: relative;
    width: 303px;
    text-align: center;
}
.coming-soon-page .top-cutdown-area .left-logo-area p {
    margin-bottom: 0;
    color: #fff;
}
.coming-soon-page .top-cutdown-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;
}

.coming-soon-page .top-cutdown-area .left-logo-area {
    position: relative;
}

.coming-soon-page .top-cutdown-area .right-area {
    position: relative;
    display: flex;
    align-items: center;
}

.coming-soon-page .top-cutdown-area .countdown-timer-time {
    position: relative;
    display: flex;
    align-items: center;
}
.coming-soon-page .top-cutdown-area .countdown-timer-time .counter-day {
    margin-left: 20px;
    position: relative;
    text-align: center;
}

.coming-soon-page .top-cutdown-area .countdown-timer-time .counter-day h5 {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    background-color: #212535;
    padding: 13px;
    width: 53px;
    border-radius: 6px;
    box-shadow: 0 0 5px #14161f;
}

.coming-soon-page .top-cutdown-area .countdown-timer-time .counter-day p {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0;
}
.coming-soon-page .top-cutdown-area .countdown-timer-time .counter-day {
    margin-left: 20px;
    position: relative;
    text-align: center;
}

.coming-soon-page .top-cutdown-area .countdown-timer-time .counter-day h5 {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    background-color: #212535;
    padding: 13px;
    width: 53px;
    border-radius: 6px;
    box-shadow: 0 0 5px #14161f;
}

.coming-soon-page .top-cutdown-area .countdown-timer-time .counter-day p {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0;
}

.coming-soon-page .singup-area {
    position: relative;
}

.coming-soon-page h2 {
    font-size: 40px;
    text-align: center;
    color: #fff;
    font-weight: 600;
}

.coming-soon-page .singup-area .form-box {
    margin: 25px auto 0;
}

.coming-soon-page .singup-area .form-box h3 {
    font-size: 23px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    margin-top: 0;
    line-height: 1;
    margin-bottom: 16px;
}

.coming-soon-page .singup-area .form-box form {
    width: 100%;
}

.coming-soon-page .singup-area .form-box .group-form {
    margin-bottom: 26px;
    width: 100%;
    position: relative;
}

.coming-soon-page .singup-area .form-box .group-form input {
    width: 100%;
    padding: 18px 15px;
    background-color: #1d202e;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
}

.coming-soon-page .singup-area .form-box .group-form input::placeholder {
    color: #ffffff30;
}
.coming-soon-page .singup-area .form-box .group-form button {
    position: relative;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    border: 2px solid #009600;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    padding: 18px 30px;
    background-color: #009600;
    border-radius: 10px;
    z-index: 9;
    overflow: hidden;
    font-family: "Roboto", sans-serif;
    width: 100%;
}
.coming-soon-page .singup-area .form-box .group-form button:hover{
    background-color: transparent;
    color: #009600;
    border: 2px solid #009600;
}
.coming-soon-page .singup-area .form-box {
    margin: 25px auto 0;
}
.coming-soon-page .social-box {
    position: relative;
    margin-top: 29px;
}

.coming-soon-page .social-box ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
}

.coming-soon-page .social-box ul li {
    margin: 0 8px;
}

.coming-soon-page .social-box ul li .social-link {
    display: block;
    width: 40px;
    background-color: #1d202e;
    text-align: center;
    height: 40px;
    padding: 6px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    border: 1px solid #1d202e;
}

.coming-soon-page .social-box ul li .social-link img {
    width: 78%;
}

.coming-soon-page .social-box ul li .social-link:hover {
    border: 1px solid #fff;
    background-color: transparent;
}
.coming-soon-page .singup-area p {
    font-size: 14px;
    text-align: center;
    color: #ffffff90;
}
.comingsoon-box {
    margin-top: 20px;
    text-align: right;
    color: #fff;
}

.comingsoon-box h2 {
    font-size: 43px;
    color: #fff;
    font-weight: 600;
}
.coming-soon-page h2 span {
    font-family: 'Jumble';
    font-weight: 400;
}
.coming-soon-page h2 span.green {
    color: #009600;
}

.coming-soon-page h2 span.light-green {
    color: #006E00;
}
.coming-soon-page .top-cutdown-area .left-logo-area p {
    margin-bottom: 0;
    color: #fff;
}

.coming-soon-page .row-center {
    align-items: center;
    margin-top: 60px;
}

.coming-soon-page .img-box {
    width: 100%;
    text-align: right;
    position: relative;
    left: -18px;
}

.coming-soon-page .img-box.text-left4 {
    text-align: left;
    left: auto;
    right: -17px;
}

.coming-soon-page .img-box img {
    width: 266px;
}

.coming-soon-page .row-center .img-box {
    margin-top: -134px;
}
.coming-soon-page h3 {
    font-size: 33px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    margin-top: 35px;
    line-height: 57px;
}
.coming-soon-page .top-cutdown-area .right-area button {
    margin-top: -24px;
    margin-left: 15px;
}
@media(max-width: 1610px){
    .coming-soon-page h2 {
        font-size: 35px;
        margin-bottom: -24px;
    }
    .coming-soon-page .row-center {
        margin-top: 23px;
    }
    .coming-soon-page .singup-area .form-box .group-form input,
    .coming-soon-page .singup-area .form-box .group-form button {
        padding: 10px 15px;
    }
    .coming-soon-page .social-box {
        position: relative;
        margin-top: 20px;
    }
    .coming-soon-page .singup-area .form-box .group-form {
        margin-bottom: 15px;
    }
    .comingsoon-box {
        margin-top: 0;
        position: absolute;
        right: 0;
        bottom: -20px;
    }
    .coming-soon-page h3{
        font-size: 27px;
        line-height: 47px;
    }
    .coming-soon-page .singup-area .form-box {
        margin: 9px auto 0;
    }    
}

@media(max-width: 1410px){
    .coming-soon-page .top-cutdown-area .left-logo-area img {
        width: 58%;
    }
    .coming-soon-page .top-cutdown-area .countdown-timer-time .counter-day h5{
        padding: 10px;
        width: 43px;
        font-size: 19px;
    }
    .coming-soon-page h2 {
        font-size: 28px;
    }
    .coming-soon-page .singup-area .form-box {
        margin: 15px auto 0;
    }
    .coming-soon-page .singup-area .form-box .group-form input {
        padding: 8px 15px;
    }
    .coming-soon-page .singup-area .form-box .group-form {
        margin-bottom: 10px;
    }
    .coming-soon-page .social-box ul li .social-link{
        display: block;
        width: 33px;
        height: 33px;
        padding: 4px;
    }
    .coming-soon-page .social-box ul li .social-link img {
        width: 72%;
    }
    .comingsoon-box h2 {
        font-size: 27px;
    }
    .coming-soon-page .row-center {
        margin-top: 0;
    }
    .coming-soon-page h3 {
        font-size: 22px;
        line-height: 39px;
    }
    .coming-soon-page .singup-area .form-box h3 {
        font-size: 18px;
    }
    .coming-soon-page .singup-area .form-box .group-form input, .coming-soon-page .singup-area .form-box .group-form button {
        padding: 7px 15px;
    }
    .coming-soon-page .img-box{
        left: 14px;
    }
    .coming-soon-page .img-box.text-left4 {
        right: 34px;
    }
    .coming-soon-page .img-box img {
        width: 220px;
    }
    .coming-soon-page .row-center .img-box {
        margin-top: -117px;
    }
}
@media(max-width: 1300px){
    .coming-soon-page .singup-area .form-box {
        width: 100%;
        margin: 15px auto 0;
    }
}
@media(max-width: 1100px){
    .coming-soon-page{
        overflow: auto;
    }
}
@media(max-width: 991px){
    .coming-soon-page .singup-area .form-box {
        width: 100%;
        margin: 15px auto 0;
    }
    .coming-soon-page .singup-area {
        width: 100%;
    }
    .coming-soon-page .row-center .img-box {
        margin-top: 0;
        right: auto;
        left: auto;
        width: 100%;
        text-align: center;
    }
    
}

@media(max-width: 767px){
    .coming-soon-page .singup-area {
        width: 100%;
    }
    .coming-soon-page .top-cutdown-area .left-logo-area img {
        width: 200px;
    }
    .comingsoon-box {
        margin-top: 6px;
        text-align: center;
        right: auto;
        position: relative;
        bottom: auto;
    }
    .coming-soon-page h3 {
        font-size: 15px;
        line-height: 26px;
    }
    .coming-soon-page .top-cutdown-area{
        flex-direction: column;
    }
    .coming-soon-page .top-cutdown-area .right-area {
        margin-top: 14px;
    }
}

@media(max-width: 500px){
    .coming-soon-page .top-cutdown-area {
        flex-direction: column;
    }
    
    .coming-soon-page .top-cutdown-area .left-logo-area {
        margin-bottom: 13px;
    }
    .coming-soon-page h2 {
        font-size: 20px;
    }
    .coming-soon-page .singup-area .form-box h3{
        font-size: 16px;
    }
    .coming-soon-page .singup-area p{
        font-size: 12px;
    }
    .comingsoon-box h2 {
        font-size: 24px;
    }
    .coming-soon-page .top-cutdown-area .right-area {
        flex-direction: column;
    }
    .coming-soon-page .top-cutdown-area .right-area button {
        margin-top: 10px;
        margin-left: 0;
    }
}

@media(max-width: 380px){
    .coming-soon-page h2,
    .comingsoon-box h2 {
        font-size: 16px;
    }
}
