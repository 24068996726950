.cheeba-nft-collection-page {
    background: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    color: #5A5A5A;
}

::selection {
    background-color: #49AEFD;
    color: #fff;
}

a {
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}
.sec-tittle {
    display: flex;
    justify-content: center;
    position: relative;
    width: 34%;
    margin: 0 auto 30px;
}

.sec-tittle h2 {
    text-align: center;
    position: relative;
    background: #FFFFFF;
    box-shadow: 7px 10px 20px rgba(91, 143, 222, 0.4), inset 8px 8px 16px rgba(223, 236, 255, 0.7), inset -15px -15px 30px rgba(188, 215, 255, 0.6);
    border-radius: 25px;
    z-index: 9;
    width: 100%;
    padding: 12px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 140%;
    /* identical to box height, or 48px */
    text-align: center;
    color: #293A49;
    transition: all 0.3s ease-in-out;
}

.sec-tittle h2:after {
    content: "";
    position: absolute;
    right: -5px;
    bottom: -5px;
    width: 45px;
    height: 40px;
    background: #F2F7FF;
    box-shadow: -2px 0px 10px rgba(26, 127, 206, 0.4), inset 2px 2px 3px #E3EEFF, inset -4px -4px 8px #E1EDFF;
    border-radius: 24px 0px;
    z-index: 1;
    transition: all 0.3s ease-in-out;
}

.sec-tittle h2:before {
    content: "";
    position: absolute;
    left: -5px;
    top: -5px;
    width: 45px;
    height: 40px;
    background: #49AEFD;
    box-shadow: 2px 4px 10px rgba(26, 127, 206, 0.6), inset 2px 2px 3px #58BFFB, inset -4px -4px 8px #368BF3;
    border-radius: 24px 0px;
    transition: all 0.3s ease-in-out;
}

.sec-tittle:hover h2{
    box-shadow: 7px 10px 20px rgba(0, 0, 0, 0.4), inset 8px 8px 16px rgba(223, 236, 255, 0.7), inset -15px -15px 30px rgba(0, 0, 0, 0.2);
}

.sec-tittle:hover h2:before{
    background: #F2F7FF;
    box-shadow: -2px 0px 10px rgba(26, 127, 206, 0.4), inset 2px 2px 3px #E3EEFF, inset -4px -4px 8px #E1EDFF;
}

.sec-tittle:hover h2:after{
    background: #49AEFD;
    box-shadow: 2px 4px 10px rgba(26, 127, 206, 0.6), inset 2px 2px 3px #58BFFB, inset -4px -4px 8px #368BF3;
}

.btn-style-one {
    position: relative;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    border: 2px solid #2f5bf9;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    padding: 8px 30px;
    background-color: #2f5bf9;
    border-radius: 10px;
    z-index: 9;
    overflow: hidden;
    font-family: "Roboto", sans-serif;
}

.btn-style-one:hover {
    background-color: transparent;
    color: #2f5bf9;
    border: 2px solid #2f5bf9;
}

.btn-style-two {
    position: relative;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    color: #1e2329;
    border: 1px solid #ececec;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    padding: 8px 30px;
    background-color: #ececec;
    border-radius: 10px;
    z-index: 9;
    overflow: hidden;
    font-family: "Roboto", sans-serif;
}

.btn-style-two:hover {
    background-color: #1e2329;
    color: #fff;
    border: 1px solid #1e2329;
}

.btn-style-three {
    position: relative;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    border: 1px solid #009600;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    padding: 8px 30px;
    background-color: #009600;
    border-radius: 10px;
    z-index: 9;
    overflow: hidden;
    font-family: "Roboto", sans-serif;
}

.btn-style-three:hover {
    background-color: #006e00;
    color: #fff;
    border: 1px solid #006e00;
}

video#v0 {
    object-fit: cover;
    width: 100%;
}

a:hover,
a:focus,
a:visited {
    text-decoration: none;
    outline: none;
    text-decoration: none !important;
}
.blue-text{
    color: #156CF7;
}
.cheeba-nft-collection-page .banner-sec {
    position: relative;
    padding-bottom: 23px;
}
.cheeba-nft-collection-page .banner-sec .inner-container {
    position: relative;
    background-color: #000;
    border-radius: 31px;
    box-shadow: 0 0 15px rgba(0 0 0 / 20%);
}

.cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner {
    padding: 25px;
    position: relative;
    text-align: center;
    margin-right: 25px;
}
.cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner .banner-btn {
    background-color: #fff;
    padding: 9px 33px;
    border-radius: 29px;
    margin-top: 18px;
    display: inline-block;
    color: #000000;
    border: 1px solid #fff;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner .banner-btn:hover {
    background-color: #009600;
    color: #fff;
    border-color: #009600;
}
.cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner h1 img {
    width: 73%;
}
.cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner h1 {
    padding: 53px 0 44px;
}
.cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner h2 {
    font-size: 38px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
}
.planet-sec .nft-box .overlaybox {
    position: relative;
    padding: 40px 0 20px;
}

.planet-sec .nft-box .overlaybox h2 {
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
}

.planet-sec .nft-box .overlaybox h3 {
    color: #ffffff90;
    font-family: "Roboto", sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
}
.cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner h3 {
    font-size: 23px;
    color: #ffffff90;
}
.cheeba-nft-collection-page .banner-sec .img-box-banner{
    position: relative;
}
.cheeba-nft-collection-page .banner-sec .img-box-banner video#bannerVideo {
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: -6px;
    border-radius: 34px;
}
.gold-text{
    color: #fcd535;
}
.bottom-sec-title .title-area {
    border-bottom: 1px solid #f0f0f0;
    position: relative;
}

.bottom-sec-title {
    position: relative;
    padding: 0px 0 30px;
}

.bottom-sec-title .title-area h4 {
    font-size: 20px;
    color: #1f2130;
    font-weight: 800;
}
.bottom-sec-title .title-area h1 {
    font-size: 28px;
    color: #1f2130;
    font-weight: 800;
}

.bottom-sec-title .title-area h2 {
    font-size: 20px;
    color: #5b667c;
    padding-bottom: 35px;
}
.bottom-sec-title .title-area p {
    font-size: 16px;
    color: #5b667c;
}

.sec-title {
    position: relative;
    margin-bottom: 30px;
}

.sec-title h2 {
    color: #1f2130;
    font-family: "Roboto", sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
}
.green-title.sec-title h2 {
    color: #006E00;
}
.sec-title h3 {
    color: #5b667c;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
}
.tranding-sec {
    position: relative;
    padding: 30px 0 20px;
}

.date-box {
    position: relative;
    display: flex;
    justify-content: center;
}

.date-box .dateinner {
    background-color: #f6f6f6;
    width: 183px;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}
.date-box .dateinner:hover, .counter-box .counterinner:hover {
    box-shadow: 5px 5px 3px rgba(0 0 0 / 15%);
}
.date-box .dateinner .icon {
    width: 22px;
    margin-right: 15px;
}

.date-box .dateinner p {
    margin-bottom: 0;
    color: #7d8597;
    font-weight: 600;
}

.counter-box {
    position: relative;
    display: flex;
    justify-content: end;
    align-items: center;
}
.counter-box h4 {
    font-size: 19px;
    font-weight: 800;
    margin-right: 15px;
    margin-bottom: 0;
}
.counter-box .counterinner {
    display: flex;
    align-items: center;
    width: 246px;
    background-color: #f6f6f6;
    padding: 10px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}

.counter-box .counterinner .icon {
    width: 20px;
    margin-right: 15px;
}

.counter-box .counterinner .countdown-timer-time {
    position: relative;
    display: flex;
    align-items: center;
}

.counter-box .counterinner .countdown-timer-time .counter-day {
    display: flex;
    align-items: center;
    padding-right: 5px;
    padding-left: 5px;
    justify-content: center;
}
.counter-box .counterinner .countdown-timer-time .counter-day h5,
.counter-box .counterinner h2 {
    margin-bottom: 0;
    color: #7d8597;
    font-weight: 600;
    font-size: 18px;
    padding: 0;
}
.counter-box .counter-box-item .sale-stage {
    font-size: 11px;
    margin-bottom: 0;
}   
.counter-box .counterinner .countdown-timer-time .counter-day p {
    margin-bottom: 0;
    color: #7d8597;
    font-weight: 600;
    font-size: 18px;
    padding: 0;
}
.tranding-sec .tranding-cards {
    position: relative;
}
.modal-login.style-two .modal-area.tranding-sec .tranding-cards .tranding-box .flip-box {
    min-height: 123px;
    border-radius: 10px;
}
.modal-login.style-two .modal-area.tranding-sec .tranding-cards .tranding-box {
    border-radius: 13px;
}

.modal-login.style-two .modal-area.tranding-sec .tranding-cards .tranding-box .flip-box {
    min-height: 123px;
    border-radius: 10px;
}

.congratulations-modal {
    max-width: 850px !important;
}

.modal-login.style-two .modal-area.tranding-sec .tranding-cards .tranding-box .flip-box .img-box img {
    width: 100px;
    height: 100px;
}
.congratulations-modal {
    max-width: 850px !important;
}
.tranding-sec .tranding-cards .tranding-box {
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0 0 0 / 15%);
    border-radius: 33px;
    padding: 15px;
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out;
}
.tranding-sec .tranding-cards .tranding-box:hover {
    box-shadow: 7px 7px 2px rgba(0 0 0 / 15%);
}
.tranding-sec .tranding-cards .col,
.planet-sec .col {
    max-width: 20%;
    flex-basis: auto;
}
.tranding-sec .tranding-cards .tranding-box .img-box {
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.tranding-sec .tranding-cards .tranding-box .flip-box {
    background-color: #f4f4f5;
    border-radius: 33px;
    padding: 10px 25px;
    text-align: center;
    transition: all 0.3s ease-in-out;
    display: block;
    min-height: 203px;
}


.tranding-sec .tranding-cards .tranding-box .img-box img {
    border-radius: 11px;
    width: 172px;
    height: 172px;
    object-fit: contain;
}

.tranding-sec .tranding-cards .tranding-box .img-box .front-img {
    transition: all 0.3s ease-in-out;
    visibility: visible;
    opacity: 1;
}

.tranding-sec .tranding-cards .tranding-box .img-box .back-img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    width: 132px;
}
.congratulations-modal .detail-area {
    margin-top: 30px;
}
.congratulations-modal h5.modal-title {
    font-size: 26px !important;
}
.congratulations-modal .detail-area p{
    font-size: 18px !important;
    color: #000 !important;
    font-weight: 500;
}
.modal-login .modal-content .modal-header h5.modal-title .icon {
    display: inline-block;
    width: 26px;
    margin-right: 11px;
    position: relative;
    top: -1px;
}
.tranding-sec .tranding-cards .tranding-box:hover .img-box .front-img {
    visibility: hidden;
    opacity: 0;
}

.tranding-sec .tranding-cards .tranding-box:hover .img-box .back-img {
    opacity: 1;
    visibility: visible;
}
.tranding-sec .tranding-cards .tranding-box .flip-box-back,
.tranding-sec .tranding-cards .tranding-box .flip-box-front{
    background-size: cover;
    background-position: center;
    -ms-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    -webkit-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.tranding-sec .tranding-cards .tranding-box .flip-box-back {
    position: absolute;
    width: 90%;
    left: 14px;
    top: 24px;
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.tranding-sec .tranding-cards .tranding-box .flip-box-front {
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.tranding-sec .tranding-cards .tranding-box:hover .flip-box-front {
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.tranding-sec .tranding-cards .tranding-box:hover .flip-box-back {
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
.tranding-sec .tranding-cards .tranding-box .img-box video {
    width: 105px;
    border-radius: 9px;
}
.tranding-sec .tranding-cards .tranding-box .text-box {
    position: relative;
    padding: 15px 0 0;
}

.tranding-sec .tranding-cards .tranding-box .text-box h4 {
    color: #1f2130;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 13px;
}

.tranding-sec .tranding-cards .tranding-box .text-box .price-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tranding-sec .tranding-cards .tranding-box .text-box .price-area p {
    margin-bottom: 0;
    color: #5b667c;
    font-size: 14px;
}

.tranding-sec .tranding-cards .tranding-box .text-box .price-area p b {
    color: #1f2130;
}

.tranding-sec .tranding-cards .tranding-box .text-box .price-area .buy-btn,
.planet-sec .tranding-box .text-box .price-area .buy-btn {
    background-color: #009600;
    padding: 4px 20px;
    font-size: 12px;
    color: #fff;
    border-radius: 9px;
    border: 1px solid #009600;
    transition: all 0.3s ease-in-out;
}
.tranding-sec .tranding-cards .tranding-box .text-box .price-area .buy-btn:hover,
.planet-sec .tranding-box .text-box .price-area .buy-btn:hover{
    background-color: #006E00;
    color: #fff;
    border-color: #006E00;
}
.planet-sec {
    position: relative;
    padding: 40px 0 30px;
}

.planet-sec .nft-box {
    position: relative;
    background-color: #000;
    border-radius: 27px;
    text-align: center;
    min-height: 500px;
    padding: 33px 0;
}
.planet-sec .nft-box img {
    border-radius: 25px;
    box-shadow: 0 0 15px rgba(0 0 0 / 20%);
    width: 73%;
}
.planet-sec .nft-box .coming-soon-btn {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 35px;
    transition: all 0.3s ease-in-out;
    border: 1px solid #fff;
}

.planet-sec .nft-box .coming-soon-btn:hover {
    background-color: #009600;
    color: #fff;
    border-color: #009600;
}.allowlist-sec {
    position: relative;
    padding: 50px 0;
}

.allowlist-sec .form-box {
    position: relative;
}

.allowlist-sec .form-box h3 {
    text-align: center;
    color: #1f2130;
    font-size: 23px;
    font-weight: 800;
    margin-bottom: 18px;
}

.allowlist-sec .form-box form .group-form {
    margin-bottom: 20px;
}

.allowlist-sec .form-box form .group-form input {
    border: 1px solid #939393;
    padding: 10px 15px;
    width: 100%;
    border-radius: 8px;
    color: #000;
}

.allowlist-sec .form-box form .group-form input::placeholder {
    color: #00000040;
}

.allowlist-sec .form-box form .group-form .submit-btn {
    border: 1px solid #181a20;
    background-color: #181a20;
    color: #fff;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}

.allowlist-sec .form-box form .group-form .submit-btn:hover {
    background-color: #009600;
    color: #fff;
    border-color: #009600;
}

.allowlist-sec .form-box p {
    font-size: 13px;
    text-align: center;
    color: #000;
}
.tranding-sec .tranding-cards .tranding-box .text-box .play-btn,
.tranding-sec .tranding-cards .tranding-box .text-box .pause-btn {
    position: absolute;
    top: -19px;
    right: 0;
    background-color: #006E00;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: none;
    display: block;
    transition: all 0.3s ease-in;
}
.tranding-sec .tranding-cards .tranding-box .text-box .play-btn:hover,
.tranding-sec .tranding-cards .tranding-box .text-box .pause-btn:hover{
    background-color: #009600;
}
.tranding-sec .tranding-cards .tranding-box .text-box .play-btn img,
.tranding-sec .tranding-cards .tranding-box .text-box .pause-btn img {
    width: 12px;
    filter: brightness(200);
}
.tranding-sec .tranding-cards .tranding-box .about-game-img {
    position: absolute;
    left: 50%;
    top: 39%;
    transform: translate(-50%, -50%);
}

.tranding-sec .tranding-cards .tranding-box .about-game-img video#video {
    width: 94%;
    border-radius: 10px;
}
/* .tranding-sec .tranding-cards .tranding-box.active .about-game-img video#video,
.tranding-sec .tranding-cards .tranding-box.active .text-box .pause-btn {
    display: block;
}
.tranding-sec .tranding-cards .tranding-box.active .flip-box .flip-box-front, 
.tranding-sec .tranding-cards .tranding-box.active .flip-box .flip-box-back,
.tranding-sec .tranding-cards .tranding-box .text-box .pause-btn,
.tranding-sec .tranding-cards .tranding-box.active .text-box .play-btn{
    display: none;
} */

.tranding-sec .tranding-cards .tranding-box.slider-box .play-btn, .tranding-sec .tranding-cards .tranding-box.slider-box .pause-btn {
    text-align: center;
    margin: 0 auto;
}

.tranding-sec .tranding-cards .tranding-box.slider-box .play-btn img, .tranding-sec .tranding-cards .tranding-box.slider-box .pause-btn img {
    margin: 0 auto;
}


.planet-sec .tranding-box .flip-box {
    background-color: #f4f4f5;
    border-radius: 33px;
    padding: 10px 25px;
    text-align: center;
    transition: all 0.3s ease-in-out;
    display: block;
}


.planet-sec .tranding-box .img-box img {
    border-radius: 11px;
    width: 150px;
    height: 150px;
    object-fit: contain;
}

.planet-sec .tranding-box .img-box .front-img {
    transition: all 0.3s ease-in-out;
    visibility: visible;
    opacity: 1;
}

.planet-sec .tranding-box .img-box .back-img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.planet-sec .tranding-box:hover .img-box .front-img {
    visibility: hidden;
    opacity: 0;
}

.planet-sec .tranding-box:hover .img-box .back-img {
    opacity: 1;
    visibility: visible;
}
.planet-sec .tranding-box .flip-box-back,
.planet-sec .tranding-box .flip-box-front{
    background-size: cover;
    background-position: center;
    -ms-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    -webkit-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.planet-sec .tranding-box .flip-box-back {
    position: absolute;
    width: 90%;
    left: 14px;
    top: 24px;
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.planet-sec .tranding-box .flip-box-front {
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.planet-sec .tranding-box:hover .flip-box-front {
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.planet-sec .tranding-box:hover .flip-box-back {
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
.planet-sec .tranding-box .img-box video {
    width: 105px;
    border-radius: 9px;
}
.planet-sec .tranding-box .text-box {
    position: relative;
    padding: 15px 0 0;
}

.planet-sec .tranding-box .text-box h4 {
    color: #1f2130;
    font-size: 16px;
    font-weight: 600;
}

.planet-sec .tranding-box .text-box .price-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.planet-sec .tranding-box .text-box .price-area p {
    margin-bottom: 0;
    color: #5b667c;
    font-size: 14px;
}

.planet-sec .tranding-box .text-box .price-area p b {
    color: #1f2130;
}

.planet-sec .tranding-box {
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0 0 0 / 15%);
    border-radius: 33px;
    padding: 15px;
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out;
}
.planet-sec .tranding-box:hover {
    box-shadow: 7px 7px 2px rgba(0 0 0 / 15%);
}
.tranding-sec.tranding-black-sec,
.tranding-sec.tranding-black-sec .tranding-cards .tranding-box .flip-box,
.planet-sec.tranding-black-sec,
.planet-sec.tranding-black-sec .tranding-box .flip-box{
    background-color: #000;
}
.tranding-sec.tranding-black-sec .sec-title h2,
.tranding-sec.tranding-black-sec .counter-box h4,
.tranding-sec.tranding-black-sec .counter-box .counterinner .countdown-timer-time .counter-day h5, 
.tranding-sec.tranding-black-sec .counter-box .counterinner h2,
.tranding-sec.tranding-black-sec .tranding-cards .tranding-box .text-box h4,
.tranding-sec.tranding-black-sec .tranding-cards .tranding-box .text-box .price-area p,
.planet-sec.tranding-black-sec .sec-title h2,
.planet-sec.tranding-black-sec .counter-box h4,
.planet-sec.tranding-black-sec .counter-box .counterinner .countdown-timer-time .counter-day h5, 
.planet-sec.tranding-black-sec .counter-box .counterinner h2,
.planet-sec.tranding-black-sec .tranding-box .text-box h4,
.planet-sec.tranding-black-sec .tranding-box .text-box .price-area p
{
    color: #fff;
}
.tranding-sec.tranding-black-sec .counter-box .counterinner,
.planet-sec.tranding-black-sec .counter-box .counterinner {
    background-color: #ffffff30;
}
.sec-title.green-title {
    padding: 34px 0;
    border: 1px solid #006e00;
    border-radius: 10px;
}
.sec-title.green-title h3 {
    font-size: 18px;
}
.benifits-sec .benifits-inner .benifit-list li a {
    color: #009600;
}
.tranding-sec.tranding-black-sec .sec-title h3,
.planet-sec.tranding-black-sec .sec-title h3{
    color: #ffffff99;
}
.tranding-sec.tranding-black-sec .tranding-cards .tranding-box,
.planet-sec.tranding-black-sec .tranding-box{
    background-color: #ffffff40;
}
.tranding-sec.tranding-black-sec .tranding-cards .tranding-box .text-box .price-area p b,
.planet-sec.tranding-black-sec .tranding-box .text-box .price-area p b{
    color: #ccc;
}
.modal-login .modal-body.modal-body .buy-block {
    position: relative;
}

.modal-login .modal-body.modal-body .buy-block .shipping-info {
    position: relative;
    transition: all 0.3s ease-in-out;
}
.modal-login .modal-body.modal-body .buy-block .card-info,
.modal-login .modal-body.modal-body .buy-block.active .shipping-info {
    display: none;
}
.modal-login .modal-body.modal-body .buy-block.active .card-info,
.modal-login .modal-body.modal-body .buy-block .shipping-info {
    display: block;
}
.card-info.allowlist-sec .group-form {
    margin-bottom: 20px;
}

.card-info.allowlist-sec .group-form input {
    border: 1px solid #93939350;
    padding: 10px 15px;
    width: 100%;
    border-radius: 8px;
    color: #000;
}
.card-info.allowlist-sec .group-form input::placeholder{
    color: #00000050;
}
.card-info.allowlist-sec {
    padding: 14px 0 0;
}
.card-info.allowlist-sec .group-form .StripeElement {
    width: 100%;
    border-radius: 6px;
}
.card-info.allowlist-sec .group-form {
    width: 100%;
}

.card-info.allowlist-sec .group-form .StripeElement .__PrivateStripeElement {
    width: 100%;
}

.card-info.allowlist-sec .group-form .StripeElement iframe {
    width: 100%;
}
.card-info.allowlist-sec .group-form {
    width: 100%;
    text-align: center;
}
.card-info.allowlist-sec .group-form .my-4 {
    width: 100%;
    margin: 0 0 20px !important;
    padding: 0 15px;
}
.card-info.allowlist-sec .group-form.style-three {
    margin-bottom: 0;
    text-align: center;
}
.modal-login .modal-body.modal-body .buy-block .step-box {
    list-style: none;
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 81%;
    margin: 0 auto 16px;
}

.modal-login .modal-body.modal-body .buy-block .step-box li {
    position: relative;
    font-size: 15px;
    font-weight: 400;
    color: #212529;
}
.modal-login .modal-body.modal-body .buy-block .step-box:before {
    position: absolute;
    content: "";
    left: 50%;
    top: 12px;
    width: 37%;
    height: 2px;
    background-color: #f5f5f5;
    transform: translateX(-47%);
}

.allowlist-sec .form-box .group-form.style-three .card-btn {
    background-color: #009600 !important;
    color: #fff !important;
}

.allowlist-sec .form-box .group-form.style-three .card-btn:hover {
    background-color: #006E00;
}
.modal-login .modal-body.modal-body .buy-block .step-box li:before {
    content: "1";
    position: absolute;
    left: -34px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: #f5f5f5;
    text-align: center;
    line-height: 24px;
    border-radius: 50%;
}
.modal-login .modal-body.modal-body .buy-block .step-box li:nth-child(2):before{
    content: "2";
}
.modal-login .modal-body.modal-body .buy-block .step-box li.active {
    color: #009600;
}

.modal-login .modal-body.modal-body .buy-block .step-box li.active::before {
    background-color: #009600;
    color: #fff;
}

@media(max-width: 1610px){
    .planet-sec .nft-box .coming-soon-btn {
        zoom: 0.8;
    }
    .tranding-sec .tranding-cards .tranding-box .flip-box,
    .planet-sec .tranding-box .flip-box{
        min-height: 134px;
    }
    .tranding-sec .tranding-cards .tranding-box .img-box img{
        width: 132px;
        height: 132px;
    }
}
@media(max-width: 1450px){
    .tranding-sec .tranding-cards .tranding-box .img-box img,
    .planet-sec .tranding-box .img-box img{
        width: 90px !important;
        height: 90px !important;
    }
    .planet-sec .tranding-box .flip-box{
        min-height: 138px;
    }

    .tranding-sec .tranding-cards .tranding-box .about-game-img video#video,
    .planet-sec .tranding-box .about-game-img video#video{
        width: 100%;
    }
    .tranding-sec .tranding-cards .tranding-box .about-game-img,
    .planet-sec .tranding-box .about-game-img{
        top: 37%;
    }
    .tranding-sec .tranding-cards .tranding-box .flip-box-front,
    .planet-sec .tranding-box .flip-box-front{
        position: relative;
        top: 9px;
    }
    .tranding-sec .tranding-cards .tranding-box .flip-box-back,
    .planet-sec .tranding-box .flip-box-back{
        top: 34px;
    }
}
@media(max-width: 1367px){
    .sec-title h2{
        font-size: 21px;
    }
    .sec-title h3 {
        font-size: 15px;
    }
    .cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner h1 {
        padding: 50px 0 35px;
    }
}
@media(max-width: 1320px){
    .cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner h2{
        font-size: 27px;
    }
    .cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner h3 {
        font-size: 17px;
    }
}

@media(max-width: 1199px){
    .tranding-sec .tranding-cards .tranding-box .text-box h4,
    .planet-sec .tranding-box .text-box h4{
        font-size: 16px;
    }
    .tranding-sec .tranding-cards .tranding-box .text-box .price-area p,
    .planet-sec .tranding-box .text-box .price-area p{
        font-size: 12px;
    }
    .tranding-sec .tranding-cards .tranding-box .text-box .price-area .buy-btn,
    .planet-sec .tranding-box .text-box .price-area .buy-btn{
        font-size: 12px;
    }
    .cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner h1 {
        padding: 30px 0 35px;
    }
}
@media(max-width: 1099px){
    .tranding-sec .tranding-cards .col,
    .planet-sec .col {
        max-width: 25%;
    }
    .planet-sec .nft-box .coming-soon-btn{
        bottom: 34px;
    }
    .counter-box h4{
        font-size: 15px;
    }
    .counter-box .counterinner{
        width: 232px;
    }
    .counter-box .counterinner .icon {
        margin-right: 9px;
    }
    .sec-title h2{
        font-size: 19px;
    }
    .sec-title h3 {
        font-size: 14px;
    }
}
@media(max-width: 991px){
    .cheeba-nft-collection-page .banner-sec .img-box-banner video#bannerVideo {
        margin-left: 0;
    }
    .date-box {
        margin-bottom: 20px;
        justify-content: flex-start;
    }
    .bottom-sec-title .title-area h1 {
        font-size: 20px;
    }
    
    .bottom-sec-title .title-area h2 {
        font-size: 16px;
    }
    .bottom-sec-title .title-area h4{
        font-size: 17px;
    }
    .bottom-sec-title .title-area p {
        font-size: 14px;
    }
    .sec-title h2{
        font-size: 22px;
    }
}
@media(max-width: 890px){
    .tranding-sec .tranding-cards .col,
    .planet-sec .col {
        max-width: 33.33%;
    }
    .planet-sec .nft-box .coming-soon-btn {
        bottom: 24px;
        padding: 4px 25px;
        font-size: 14px;
    }
    .counter-box h4,
    .counter-box .counterinner .countdown-timer-time .counter-day h5,
    .counter-box .counterinner .countdown-timer-time .counter-day p {
        font-size: 14px;
    }
    .counter-box .counterinner {
        width: 214px;
    }
}
@media(max-width: 800px){
    .counter-box h4,
    .counter-box .counterinner .countdown-timer-time .counter-day h5,
    .counter-box .counterinner .countdown-timer-time .counter-day p {
        font-size: 13px;
    }
    .counter-box .counterinner {
        width: 200px;
    }
    .counter-box .counterinner .countdown-timer-time .counter-day p{
        top: 1.4px;
        position: relative;
    }
}
@media(max-width: 767px){
    .counter-box {
        margin-bottom: 20px;
        justify-content: center;
    }
    .sec-title {
        margin-bottom: 20px;
        text-align: center;
    }
    .planet-sec .nft-box .coming-soon-btn {
        zoom: 0.7;
    }
}
@media(max-width: 650px){
    .tranding-sec .tranding-cards .col,
    .planet-sec .col {
        max-width: 50%;
    }
    .counter-box,
    .date-box {
        justify-content: center;
        margin-bottom: 20px;
    }
    .bottom-sec-title .title-area p br{
        display: none;
    }
    .planet-sec .nft-box .coming-soon-btn {
        zoom: 0.5;
    }
    .cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner h2{
        font-size: 21px;
    }
    .cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner h3 {
        font-size: 14px;
    }
}
@media(max-width: 480px){
    
    .tranding-sec .tranding-cards .col,
    .planet-sec .col {
        max-width: 100%;
    }
    .tranding-sec .tranding-cards .tranding-box .img-box,
    .planet-sec .tranding-box .img-box {
        padding: 16px;
        text-align: center;
    }
    .planet-sec .nft-box .coming-soon-btn {
        zoom: 0.4;
    }
    .cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner h1 img {
        width: 100%;
    }
    .cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner h1 {
        padding: 30px 0 9px;
    }
    .cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner h2{
        font-size: 19px;
    }
    .cheeba-nft-collection-page .banner-sec .inner-container .text-box-banner h3 {
        font-size: 14px;
    }
}
@media(max-width: 380px){
    .planet-sec .nft-box .coming-soon-btn {
        zoom: 0.4;
    }
    .counter-box .counterinner {
        width: 200px;
        zoom: 0.8;
    }
    .counter-box .counterinner .countdown-timer-time .counter-day p {
        top: 0;
    }
}
@media(max-width: 340px){
    .counter-box .counterinner {
        zoom: 0.7;
    }
    .counter-box h4 {
        font-size: 11px;
    }
    .counter-box .counterinner .countdown-timer-time .counter-day p {
        top: 1px;
    }
}