.product-detail-sec {
    position: relative;
    padding-top: 25px;
}

.product-detail-sec .larg-slider {
    position: relative;
    background-color: #e7e7e7;
    padding: 20px 22px;
    margin-bottom: 12px;
}
.tranding-sec .tranding-cards .slick-slide {
    padding: 15px;
}

.tranding-sec .tranding-cards .slick-slide .tranding-box .img-box img {
    display: inline-block;
}
.product-detail-sec .larg-slider .slick-slider .item {
    position: relative;
}

.product-detail-sec .larg-slider .slick-slider .item .about-game-img {
    position: relative;
    text-align: center;
}

.product-detail-sec .larg-slider .slick-slider .item .about-game-img video {
    width: 71%;
    height: 586px;
    margin: 0 auto;
    text-align: center;
    object-fit: cover;
}
.product-detail-sec .larg-slider .slick-slider .item .about-game-img img {
    margin: 0 auto;
    width: 420px;
}
.product-detail-sec .larg-slider .slick-arrow {
    display: none !important;
}

.slick-slider.slider-nav-thumbnails.slick-initialized .slick-list {
    width: 42%;
    margin: 0 auto;
}
.slick-slider.slider-nav-thumbnails.slick-initialized .slick-list .slick-track .slick-slide {
    padding: 0 5px;
    cursor: pointer;
}
.slick-slider.slider-nav-thumbnails.slick-initialized .slick-list .slick-track .item-thumbnail {
    background-color: #e7e7e7;
    border: 1px solid #e7e7e7;
    padding: 5px;
}
.slick-slider.slider-nav-thumbnails.slick-initialized .slick-list .slick-track .slick-slide.slick-current .item-thumbnail{
    border: 1px solid #2f5bf9;
}
.slick-slider.slider-nav-thumbnails.slick-initialized .slick-list .slick-track .item-thumbnail .about-game-img {
    position: relative;
}

.slick-slider.slider-nav-thumbnails.slick-initialized .slick-list .slick-track .item-thumbnail .about-game-img .overlay-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.slick-slider.slider-nav-thumbnails.slick-initialized .slick-list .slick-track .item-thumbnail .about-game-img .overlay-box .video-icon {
    background-color: transparent;
    border: none;
}

.slick-slider.slider-nav-thumbnails.slick-initialized .slick-list .slick-track .item-thumbnail .about-game-img .overlay-box .video-icon img {
    width: 19px;
}
.slick-slider.slider-nav-thumbnails.slick-initialized .slick-list .slick-track .item-thumbnail .about-game-img img {
    object-fit: contain;
    width: 70px;
    height: 58px;
}
.product-detail-sec .larg-slider .slick-list .slick-track {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.product-detail-sec .right-content h2 {
    color: #1f2130;
    font-size: 30px;
    font-weight: 600;
}

.product-detail-sec .right-content {
    position: relative;
}

.product-detail-sec .right-content p {
    font-size: 16px;
    color: #5b667c;
    font-weight: 500;
}

.product-detail-sec .right-content .price-area {
    position: relative;
    margin-bottom: 22px;
}

.product-detail-sec .right-content .price-area p {
    margin-bottom: 5px;
}

.product-detail-sec .right-content .price-area h2 {
    color: #1f2130;
    font-size: 32px;
}

.product-detail-sec .right-content .price-area h2 span {
    margin-left: 15px;
    color: #5b667c;
    font-size: 23px;
}

.product-detail-sec .right-content .table-area {
    position: relative;
    border: 1px solid #e5e5ea;
}

.product-detail-sec .right-content .table-area h3 {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: #1f2130;
    background-color: #f6f6f6;
    margin-bottom: 0;
    padding: 15px;
}

.product-detail-sec .right-content .table-area .table-responsive .table thead tr th {
    text-align: center;
    border-bottom: none;
    border-top: 1px solid #e5e5ea;
    font-size: 14px;
}

.product-detail-sec .right-content .table-area .table-responsive .table tbody tr td {
    border-top: none;
    border-right: 1px solid #e5e5ea;
    text-align: center;
    font-size: 14px;
    padding: 5px;
    color: #5b667c;
    font-weight: 500;
}
.StripeElement {
    border: 1px solid #e7e7eb;
    padding: 11px 15px;
    background-color: transparent;
    font-size: 14px;
}

.StripeElement .__PrivateStripeElement {
    font-size: 14px;
}
.modal-login.style-two .modal-area .group-form .card-btn {
    background-color: #ededef;
    border: none;
    padding: 14px 14px;
    font-size: 16px;
    border-radius: 6px;
    font-weight: 500;
    color: #131418;
}
.product-detail-sec .right-content .btn-area {
    margin-top: 20px;
}

.product-detail-sec .right-content .btn-area .btn-style-one {
    margin-bottom: 11px;
    background-color: #009600;
    color: #fff;
    border: 2px solid #009600;
}
.product-detail-sec .right-content .btn-area .btn-style-one:hover {
    background-color: #006E00;
    border-color: #006E00;
    color: #fff;
}
.product-detail-sec .right-content .btn-area button {
    min-width: 51%;
}

.product-detail-sec .bottom-slider #accordion {
    position: relative;
}

.product-detail-sec .bottom-slider #accordion .card {
    position: relative;
    background-color: transparent;
    border: none;
    padding-left: 0;
    margin-bottom: 20px;
}

.product-detail-sec .bottom-slider #accordion .card .card-header {
    border-radius: 0;
    background-color: transparent;
    padding: 0;
    border: none;
}

.product-detail-sec .bottom-slider #accordion .card .card-header h5 {
    width: 100%;
}

.product-detail-sec .bottom-slider #accordion .card .card-header h5 .btn.btn-link {
    width: 100%;
    text-align: left;
    color: #1f2130;
    font-size: 18px;
    text-decoration: none !important;
    position: relative;
    transition: all 0.3s ease-in-out;
    border: 1px solid #e5e5ea;
    border-radius: 0;
    padding: 10px;
    font-weight: 700;
}
.slick-slider.slider-nav-thumbnails {
    margin-bottom: 30px;
}

.product-detail-sec .bottom-slider #accordion .card .card-header h5 .btn.btn-link:before {
    content: "";
    position: absolute;
    right: 15px;
    top: 10px;
    width: 13px;
    height: 18px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url('../../static/images/bottom-arrow.png');
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;
}

.product-detail-sec .bottom-slider #accordion .card .card-header h5 .btn.btn-link.collapsed:before {
    transform: rotate(0deg);
    top: 16px;
}

.product-detail-sec .bottom-slider #accordion .card .card-header h5 .btn.btn-link span {
    display: block;
    font-size: 14px;
    color: #9F9F9F;
}

.product-detail-sec .bottom-slider #accordion .card .collapse {
    position: relative;
    padding: 0;
}

.product-detail-sec .bottom-slider #accordion .card .collapse .card-body {
    position: relative;
    padding: 10px 0 0;
    margin-top: 0;
    border: 1px solid #e5e5ea;
    border-top: none;
}
.product-detail-sec .bottom-slider #accordion .card .collapse .card-body .traits-column {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.product-detail-sec .bottom-slider #accordion .card .collapse .card-body .traits-column .traits-box {
    width: 31%;
    background-color: #f8f8f8;
    margin-left: 10px;
    margin-bottom: 10px;
    text-align: center;
    padding: 10px;
    min-height: 84px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.product-detail-sec .bottom-slider #accordion .card .collapse .card-body .traits-column .traits-box p {
    font-size: 12px;
    margin-bottom: 0;
    color: #5b667c;
    width: 100%;
}

.product-detail-sec .bottom-slider #accordion .card .collapse .card-body .traits-column .traits-box h4 {
    font-size: 13px;
    margin: 5px 0;
    color: #1f2130;
    font-weight: 600;
    width: 100%;
}

.product-detail-sec .bottom-slider #accordion .card .collapse .card-body .traits-column .traits-box h4 span {
    color: #5b667c;
}
.product-detail-sec .bottom-slider #accordion .card .collapse .card-body .detial-box {
    position: relative;
    padding: 10px 10px 0;
}

.product-detail-sec .bottom-slider #accordion .card .collapse .card-body .detial-box ul {
    list-style: none;
    margin-bottom: 0;
}

.product-detail-sec .bottom-slider #accordion .card .collapse .card-body .detial-box ul li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
    color: #5b667c;
}

.product-detail-sec .bottom-slider #accordion .card .collapse .card-body .detial-box ul li span {
    font-weight: 500;
    color: #1f2130;
}

.product-detail-sec .bottom-slider #accordion .card .collapse .card-body .detial-box ul li span.style-two {
    color: #5b667c;
}

.product-detail-sec .bottom-slider #accordion .card .collapse .card-body .detial-box p {
    font-size: 14px;
    margin-bottom: 15px;
    color: #5b667c;
    font-weight: 500;
}
.modal-login .modal-area .form-area .bottom-text {
    font-size: 13px;
    text-align: center;
    color: #21252990;
}

.modal-login .modal-content {
    border-radius: 8px;
    border: none;
}

.modal-login .modal-content .modal-header {
    background-color: #f6f6f6;
    border-radius: 8px 8px 0 0;
    text-align: center;
    justify-content: center !important;
    width: 100%;
}

.modal-login .modal-content .modal-header h5.modal-title {
    text-align: center;
    width: 100%;
    color: #161c31;
    font-size: 20px;
    font-weight: 600;
}
.btn-header {
    background-color: #000;
    border-color: #000;
    color: #fff;
    padding: 5px 25px !important;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    border-radius: 9px;
}

.btn-header:hover {
    background-color: transparent;
    color: #000;
}
.modal-login .modal-area .table td {
    border-top: none;
    vertical-align: middle;
}

.modal-login .modal-area .table td .MuiButtonBase-root {
    padding: 0;
    background-color: #ececec;
    width: 13px;
    height: 13px;
}

.modal-login .modal-area .table td .MuiButtonBase-root svg.MuiSvgIcon-root {
    fill: #ececec;
    width: 13px;
    height: 13px;
    position: relative;
    top: -8px;
}
.modal-login .modal-area .table td.buy-nft .MuiButtonBase-root .MuiSvgIcon-root {
    top: 0;
}
.modal-login .modal-area .table td .MuiButtonBase-root.Mui-checked svg.MuiSvgIcon, .modal-login .modal-area .table td .MuiButtonBase-root.MuiCheckbox-indeterminate svg.MuiSvgIcon-root {
    fill: #25a225;
}

.modal-login .modal-area .table td .MuiButtonBase-root.Mui-checked svg.MuiSvgIcon-root, .modal-login .modal-area .table td .MuiButtonBase-root.MuiCheckbox-indeterminate svg.MuiSvgIcon-root {
    fill: #25a225;
}

.modal-login .modal-area .table td .about-game-img {
    position: relative;
    width: 40px;
    background-color: #ececec;
    padding: 3px;
}

.modal-login .modal-area .table td .about-game-img img {
    width: 36px;
    margin: 0 auto;
}

.modal-login .modal-area .table td .about-game-img .overlay-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-login .modal-area .table td .about-game-img .overlay-box button.video-icon {
    background-color: transparent;
    border: none;
}

.modal-login .modal-area .table td .about-game-img .overlay-box button.video-icon img {
    width: 14px;
}

.modal-login .modal-area .table td label {
    font-size: 14px;
    font-weight: 600;
    color: #161c31;
}

.modal-login .modal-area .table td input[type="number"] {
    width: 42px;
    background-color: #f2f2f2;
    border: none;
    text-align: center;
    font-size: 15px;
}

.modal-login .modal-area .table td:first-child {
    padding: 0;
}
.modal-login.style-two .modal-area {
    padding: 0 10px 10px;
}

.modal-login .modal-area .table td:nth-child(2) {
    width: 50px;
}
.modal-login .modal-area .table td:last-child {
    text-align: right;
}


.modal-login .modal-area .switch {
    position: relative;
    width: 48px;
    height: 24px;
    margin-top: 7px;
}
  
  .modal-login .modal-area .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .modal-login .modal-area .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #30c454;
    transition: .4s;
}
  .modal-login .modal-area .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .modal-login .modal-area input:checked + .slider {
    background-color: #32a1fb;
  }
  
  .modal-login .modal-area input:focus + .slider {
    box-shadow: 0 0 1px #32a1fb;
  }
  
  .modal-login .modal-area input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .modal-login .modal-area .slider.round {
    border-radius: 34px;
  }
  
  .modal-login .modal-area .slider.round:before {
    border-radius: 50%;
  }
  .modal-login .modal-area .form-area .group-form.style-two {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #e7e7eb;
    padding: 11px 15px;
    background-color: transparent;
}
.modal-login .modal-area .form-area .group-form.style-two .icon img {
    width: 79%;
    margin: 0 auto;
}
.modal-login .modal-area .form-area .group-form.style-two .icon.style-two {
    text-align: right;
}

.modal-login .modal-area .form-area .group-form label {
    color: #5b667c;
    font-size: 14px;
    margin-bottom: 10px;
}

.modal-login .modal-area .form-area .group-form.style-three input {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #e7e7eb;
    background-color: transparent;
}
.modal-login .modal-area .form-area .group-form.style-three input::placeholder{
    color: #00000050;
}
.modal-login .modal-area .form-area .group-form.style-three label.text-center {
    text-align: center;
    width: 100%;
}

.modal-login .modal-area .form-area .group-form .confrim-btn {
    background-color: #aeaeae;
    width: 100%;
    border: 1px solid #aeaeae;
    transition: all 0.3s ease-in-out;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
}
.modal-login .modal-area .form-area .group-form .confrim-btn:hover {
    background-color: #161c31;
    border: 1px solid #161c31;
}
.modal-login .modal-area .form-area .group-form.style-four {
    position: relative;
}

.modal-login .modal-area .form-area .group-form h4 {
    color: #161c31;
    font-size: 18px;
    font-weight: 600;
}

.modal-login .modal-area .form-area .group-form .radio-eth-area {
    display: flex;
    align-items: center;
    margin: 0 40px;
}

.modal-login .modal-area .form-area .group-form .radio-eth-area .radio-bx {
    width: 50%;
    border: 1px solid #f1f1f4;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 51px;
    margin-left: 10px;
    margin-right: 10px;
}

.product-detail-sec .bottom-slider #accordion .card .collapse .card-body .traits-column h5 {
    font-size: 14px;
    margin-bottom: 15px;
    color: #5b667c;
    font-weight: 500;
}

.modal-login .modal-area .form-area .group-form .radio-eth-area .radio-bx span.MuiButtonBase-root {
    padding: 0;
    margin: 0;
}

.modal-login .modal-area .form-area .group-form .radio-eth-area .radio-bx span.MuiButtonBase-root input {
    padding: 0 !important;
}

.modal-login .modal-area .form-area .group-form .radio-eth-area .radio-bx p {
    margin-bottom: 0;
    margin-right: 17px;
}
.modal-login .modal-area .form-area .group-form .blance-area {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}

.modal-login .modal-area .form-area .group-form .blance-area span {
    color: #ab2b26;
}

.modal-login .modal-area .form-area .group-form.style-three button {
    width: 100%;
    transition: all 0.3s ease-in-out;
    background-color: hsl(256, 6.0%, 93.2%);
}
.modal-login .modal-area .form-area .group-form.style-three button:hover {
    color: #fff;
    background-color: #009600;
}

.modal-login .modal-area .form-area .group-form.style-three button.btn-style-one {
    margin-bottom: 14px;
}

.modal-login .modal-area .form-area .group-form input.monthyear {
    position: absolute;
    right: 9px;
    top: 11px;
    border: none;
    background-color: transparent;
    color: #5b667c;
    transition: all 0.3s ease-in-out;
    width: 80px !important;
    padding: 0;
}

.modal-login .modal-area .check-area {
    text-align: center;
    margin-bottom: 21px;
}

.modal-login .modal-area .detail-area {
    position: relative;
    text-align: center;
}

.modal-login .modal-area .detail-area h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
    color: #161c31;
}

.modal-login .modal-area .detail-area p {
    font-size: 14px;
    color: #7d8597;
    margin-bottom: 6px;
}

.geen-text {
    color: #4cae00;
}

.modal-login .modal-area .detail-area .btn-style-one {
    margin-top: 30px;
}


.modal-login .modal-area .table td .text-price,
.modal-login .modal-body.modal-body .buy-block .shipping-info .table td .text-price {
    text-align: center;
    display: inline-block;
    transition: all 0.3s ease-in-out;
}

.modal-login .modal-area .table td:hover .text-price,
.modal-login .modal-body.modal-body .buy-block .shipping-info .table td:hover .text-price {
    display: none;
}

.modal-login .modal-area .table td .remove-btn,
.modal-login .modal-body.modal-body .buy-block .shipping-info .table td .remove-btn {
    background-color: transparent;
    border: none;
    text-align: center;
    margin: 0 auto;
    transition: all 0.3s ease-in-out;
    display: none;
}

.modal-login .modal-area .table td:hover .remove-btn,
.modal-login .modal-body.modal-body .buy-block .shipping-info .table td .remove-btn {
    display: inline-block;
}

.modal-login .modal-area .table,
.modal-login .modal-body.modal-body .buy-block .shipping-info .table {
    position: relative;
}

.modal-login .modal-area .table tbody,
.modal-login .modal-body.modal-body .buy-block .shipping-info .table tbody{
    position: relative;
}

.modal-login .modal-area .table tbody tr,
.modal-login .modal-body.modal-body .buy-block .shipping-info .table tbody tr {
    position: relative;
    transition: all 0.3s ease-in-out;
    border-bottom: 5px solid #fff;
}

.modal-login .modal-area .table tbody tr:hover, 
.modal-login .modal-area .table tbody tr.active,
.modal-login .modal-body.modal-body .buy-block .shipping-info .table tbody tr:hover,
.modal-login .modal-body.modal-body .buy-block .shipping-info .table tbody tr.active {
    background-color: #f9f9f9;
}

.modal-login .modal-area .table td .remove-btn img,
.modal-login .modal-body.modal-body .buy-block .shipping-info .table td .remove-btn img {
    width: 15px;
    margin: 0 auto;
    text-align: center;
}
.modal-login .modal-area .top-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.modal-login .modal-area .top-area .left-area {
    display: flex;
    align-items: center;
}

.modal-login .modal-area .top-area .left-area .select-area {
    border: none;
    background-color: transparent;
    position: relative;
    top: -1px;
    transition: all 0.3s ease-in-out;
}

.modal-login .modal-area .top-area .left-area p {
    margin-bottom: 0;
    font-size: 13px;
    color: #7d8597;
    margin-left: 5px;
}

.modal-login .modal-area .top-area .left-area .select-area img {
    width: 9px;
}

.modal-login .modal-area .top-area .right-area {
    position: relative;
}

.modal-login .modal-area .top-area .right-area .select-area {
    background-color: transparent;
    border: none;
    font-size: 13px;
    color: #161c31;
    transition: all 0.3s ease-in-out;
}
.modal-login .modal-area .top-area .right-area .select-area:hover,
.modal-login .modal-area .top-area .left-area .select-area{
    color: #2f5bf9;
}
.product-detail-sec .right-content .table-area .table-responsive .table tbody tr.background-table {
    background-color: #f6f6f6;
    border-top: 1px solid #e5e5ea;
}

.product-detail-sec .right-content .table-area .table-responsive .table {
    margin-bottom: 0;
}
.modal-login .modal-area .form-area .group-form .radio-eth-area .nav-tabs {
    border-bottom: none;
    margin-bottom: 0;
    width: 100%;
}

.modal-login .modal-area .form-area .group-form .radio-eth-area .nav-tabs .nav-item {
    margin-bottom: 0;
    width: 50%;
    padding-right: 12px;
}

.modal-login .modal-area .form-area .group-form .radio-eth-area .nav-tabs .nav-item .nav-link {
    margin-bottom: 0 !important;
    border: 1px solid #f3f3f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 35px;
    position: relative;
}

.modal-login .modal-area .form-area .group-form .radio-eth-area .nav-tabs .nav-item .nav-link p {
    margin-bottom: 0;
    margin-right: 6px;
    font-weight: 600;
    color: #5b667c;
}

.modal-login .modal-area .form-area .group-form .radio-eth-area .nav-tabs .nav-item .nav-link:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 50%;
    width: 15px;
    height: 15px;
    border: 1px solid #5b667c;
    transform: translateY(-50%);
    border-radius: 50%;
}

.modal-login .modal-area .form-area .group-form .radio-eth-area .nav-tabs .nav-item .nav-link:after {
    content: "";
    position: absolute;
    left: 12.5px;
    top: 50%;
    width: 10px;
    height: 10px;
    background-color: #fff;
    transform: translateY(-50%);
    border-radius: 50%;
}
.modal-login .modal-area .form-area .group-form .radio-eth-area .nav-tabs .nav-item.show .nav-link:after, 
.modal-login .modal-area .form-area .group-form .radio-eth-area .nav-tabs .nav-item .nav-link.active:after{
    background-color: #2f5bf9;
}
.modal-login .modal-area .form-area .group-form .radio-eth-area .nav-tabs .nav-item.show .nav-link:before, 
.modal-login .modal-area .form-area .group-form .radio-eth-area .nav-tabs .nav-item .nav-link.active:before{
    border: 1px solid #2f5bf9;
}

.modal-confrom-btn {margin-top: 29px;}

.modal-confrom-btn .btn-style-two .icon {
    display: inline-block;
    margin-right: 5px;
    transition: all 0.3s ease-in-out;
}
.modal-confrom-btn .btn-style-two:hover{
    background-color: #2f5bf9;
    border: 1px solid #2f5bf9;
}
.modal-confrom-btn .btn-style-two:hover .icon img{
    filter: brightness(100);
}
.modal-confrom-btn .btn-style-two .icon img {
    width: 17px;
    transition: all 0.3s ease-in-out;
}

.modal-confrom-btn .btn-style-one.mr-3 {
    margin-top: 0 !important;
}
@media(max-width: 1650px){
    .product-detail-sec .larg-slider .slick-slider .item .about-game-img img {
        width: 359px;
    }
    .product-detail-sec .larg-slider .slick-slider .item .about-game-img video{
        height: 494px;
    }
}
@media(max-width: 1367px){
    .product-detail-sec .right-content h2,
    .product-detail-sec .right-content .price-area h2 {
        font-size: 24px;
    }
    .product-detail-sec .right-content p {
        font-size: 14px;
    }
    .product-detail-sec .right-content .price-area h2 span,
    .product-detail-sec .right-content .table-area h3 {
        font-size: 20px;
    }
    .product-detail-sec .right-content .table-area h3{
        padding: 10px;
    }
    .product-detail-sec .right-content .table-area .table-responsive .table tbody tr td{
        font-size: 13px;
    }
    .product-detail-sec .larg-slider .slick-slider .item .about-game-img img {
        width: 313px;
    }
    .product-detail-sec .larg-slider .slick-slider .item .about-game-img video {
        height: 429px;
    }
}

@media(max-width: 991px){
    .product-detail-sec .larg-slider .slick-slider .item .about-game-img video{
        height: 100%;
    }
    .bottom-slider {
        margin-top: 20px;
    }
}

@media(max-width: 600px){
    .product-detail-sec .larg-slider .slick-slider .item .about-game-img img {
        margin: 0 auto;
        width: 70%;
    }
    .product-detail-sec .bottom-slider #accordion .card .collapse .card-body .traits-column .traits-box{
        width: 46%;
    }
}

@media(max-width: 480px){
    .product-detail-sec .bottom-slider #accordion .card .collapse .card-body .traits-column .traits-box{
        width: 93%;
    }
}