.whatsapp-swipe {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 999;
}

.whatsapp-swipe .bottom-icon {
    width: 43px;
    display: block;
    height: 43px;
    border-radius: 50%;
}

.footer-cheeba-nft-collection {
    background-color: #181a20;
    padding: 30px 0 20px;
}

.footer-cheeba-nft-collection .footer-links {
    display: flex;
    position: relative;
    justify-content: space-between;
}

.footer-cheeba-nft-collection .footer-links ul {
    list-style: none;
}

.footer-cheeba-nft-collection .footer-links ul li {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

.footer-cheeba-nft-collection .footer-links ul li a {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}

.footer-cheeba-nft-collection .footer-links ul li a:hover {
    text-decoration: underline !important;
}

.footer-cheeba-nft-collection .social-link {
    position: relative;
    display: flex;
    justify-content: end;
}

.footer-cheeba-nft-collection .social-link ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.footer-cheeba-nft-collection .social-link ul li {
    margin-left: 18px;
}

.footer-cheeba-nft-collection .social-link ul li a {
    display: block;
    transition: all 0.3s ease-in-out;
}

.footer-cheeba-nft-collection .social-link ul li a img {
    width: 20px;
    transition: all 0.3s ease-in-out;
}

.footer-cheeba-nft-collection .social-link ul li a:hover img {
    opacity: 0.5;
}

.footer-cheeba-nft-collection .footer-links.style-two {
    justify-content: end;
}
.bottom-footer {
    position: relative;
}

.bottom-footer .copyright-text {
    text-align: right;
}

.bottom-footer .copyright-text p, .bottom-footer .content-area a {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    margin-bottom: 0;
}
.bottom-footer .copyright-text p {
    font-size: 14px;
}

.bottom-footer .content-area {
    position: relative;
    text-align: right;
}
.footer-cheeba-nft-collection .col-12 {
    padding: 0;
}
@media(max-width: 991px){
    .bottom-footer .copyright-text{
        text-align: left;
    }
}
@media(max-width: 600px){
    .footer-cheeba-nft-collection .footer-links.style-two {
        justify-content: start;
    }
    .footer-cheeba-nft-collection .social-link {
        position: relative;
        display: flex;
        justify-content: center;
    }
    .bottom-footer .content-area,
    .bottom-footer .copyright-text{
        text-align: center;
    }
}

@media(max-width: 400px){
    .footer-cheeba-nft-collection .footer-links {
        flex-direction: column;
    }
}